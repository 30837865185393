import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadSingleHotel } from "../../../redux/action/HotelAction";
import { loadAllRoom } from "../../../redux/action/RoomAction";
import { useParams, Link } from "react-router-dom";
import Loader from "../../share/Loader";
import Message from "../../share/Message";
import BookNow from "../../hotel/hotelSingle/BookNow";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FacilityHotel = () => {
  const [book, setBook] = useState(false);
  const [r_id, setR_id] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(loadSingleHotel(id));
      dispatch(loadAllRoom(id));
    }
  }, [dispatch, id]);

  const setFunction = () => {
    setBook(false);
  };

  const hotelname = useSelector((state) => state.Hotel);

  const { loading, error, singledata } = hotelname;



  let singleDatarcv = {}
  let additional_facility_name = [];
  let amenities_name = [];
  console.log('..', singledata)
  if (singledata.hoteldetails) {
    singleDatarcv = singledata.hoteldetails[0];
    additional_facility_name = singledata.additional_facility
    amenities_name = singledata.amenities;

  }


  const room = useSelector((state) => state.Room.result);

  //let facility_name = [];

  let additional_facility_image = [];
  // let amenities_name = [];
  //let amenities_type = [];

  /*
  if (singleDatarcv && "additional_facility_name" in singleDatarcv) {
    additional_facility_name = singleDatarcv.additional_facility_name.split(",");
    console.log(additional_facility_name);
  }

  if (singleDatarcv && "facility_name" in singleDatarcv) {
    facility_name = singleDatarcv.facility_name.split(",");
    console.log(facility_name);
  }

  if (singleDatarcv && "additional_facility_image" in singleDatarcv) {
    additional_facility_image = singleDatarcv.additional_facility_image.split(",");
    console.log("additional_facility_image", additional_facility_image);
  }

  if (singleDatarcv && "amenities_name" in singleDatarcv) {
    amenities_name = singleDatarcv.amenities_name.split(",");
  }

  if (singleDatarcv && "amenities_type" in singleDatarcv) {
    amenities_type = singleDatarcv.amenities_type.split(",");
  }
*/
  const handleBooking = (r_id) => {
    setBook(true);
    setR_id(r_id);
  };

  if (book !== false) {
    var deatail = <BookNow r_id={r_id} id={id} setFunction={setFunction} />;
  } else {
    deatail = (
      <div>
        <ToastContainer />
        {loading ? (
          <Loader />
        ) : error ? (
          <Message />
        ) : (
          <>
            {singleDatarcv ? (
              <div>
                <div className="container section">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="hotel-title">
                        <h2 className="title">{singleDatarcv.name}</h2>
                        <p className="hotel-address">
                          {singleDatarcv.address +
                            ", " +
                            singleDatarcv.landmark +
                            ", " +
                            singleDatarcv.pincode}
                        </p>
                        <hr />
                        <p className="hotel-address">
                          <div className="facility" dangerouslySetInnerHTML={{ __html: singleDatarcv.description }}>
                          </div>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="hotel-contact">
                        <h3>Contact to hotel</h3>
                        <h2>
                          <Link to="">
                            <span className="fa fa-phone"></span>+91
                            {singleDatarcv.mobile}
                          </Link>
                        </h2>
                        <h2>
                          <Link to="">
                            <i className="fa fa-envelope"></i>
                            {" " + singleDatarcv.email}
                          </Link>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-lg-9">
                      <div className="section facility">
                        <h4>Additional Facilities</h4>
                        <ul>
                          {additional_facility_name.map((item, i) => {
                            return (
                              <>

                                <li>{item.name}</li>

                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/*


                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-lg-9">
                      <div className="hotel-facility section">
                        <h4>Highlights</h4>
                        <ul>
                          {additional_facility_name &&
                            additional_facility_image.map((i, item) => {
                              return (
                                <li key={i}>
                                  {additional_facility_image &&
                                    additional_facility_image == null ? (
                                    ""
                                  ) : (
                                    <img
                                      src={
                                        "${process.env.REACT_APP_API_PATH}/" +
                                        additional_facility_image
                                      }
                                      alt=""
                                      width="30"
                                      height="30"
                                    />
                                  )}
                                  {additional_facility_name == null ? (
                                    ""
                                  ) : (
                                    <span>{additional_facility_name} </span>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
*/
                }
                <div className="container" id="faclity">
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="hotl-facility">
                        <h3 className="title pt-4">Amenities and facilities</h3>
                        <div className="box-modal">
                          {amenities_name.length &&
                            amenities_name.map((langu, i) => {
                              return (
                                <ul key={i}>

                                  <li>{langu.name}</li>
                                </ul>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container " id="room">
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="room-single-holtel">
                        <h2 className="title pt-4">Select Your Room</h2>
                        {room.map((item, i) => (
                          <div className="room-box">
                            <div className="appartemnt">
                              <h4>{item.room_name}</h4>
                              <ul className="appartment-image">
                                {" "}
                                {item.image && item.image == null ? (
                                  ""
                                ) : (
                                  <img src={item.image} alt="" />
                                )}
                              </ul>

                              <div className="" dangerouslySetInnerHTML={{ __html: item.description }}>
                              </div>
                            </div>

                            <div className="room-benefites">
                              <h3>Inclusions</h3>

                              <div className="" dangerouslySetInnerHTML={{ __html: item.Inclusions }}>

                              </div>

                            </div>

                            <div className="room-sleep text-center">
                              <h3>Sleeps</h3>
                              <p>{item.no_of_sleep}</p>
                            </div>

                            <div className="room-price">
                              <h3>Price per night</h3>
                              <p> CHEAPEST PRICE YOU'VE SEEN!</p>
                              <div className="prices">
                                <h3>
                                  <small
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    Rs.{item.price}
                                  </small>
                                  {/* <del></del> */}
                                </h3>

                                <h3 style={{ color: "red" }}>
                                  <small>Rs.</small>
                                  {item.book_price}
                                </h3>
                              </div>
                            </div>

                            {/* 

                            <div className="room-rooms">
                              <h3>Rooms</h3>
                              <p> 1</p>
                            </div>
                                */}

                            <div className="room-most-booked">
                              <h3>Most booked</h3>
                              <button
                                className="btn btn-primary"
                                onClick={() => handleBooking(item.id)}
                              >
                                Book Now
                              </button>
                              <div className="limit">
                                <p>Limited availability</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    );
  }

  return <div>{deatail}</div>;
};

export default FacilityHotel;
