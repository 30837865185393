export const GET_ALL_BANNER_REQUEST =
    "GET_ALL_BANNER_REQUEST";
export const GET_ALL_BANNER_SUCCESS =
    "GET_ALL_BANNER_SUCCESS";
export const GET_ALL_BANNER_FAIL =
    "GET_ALL_BANNER_FAIL";


export const GET_ALL_TESTIMONIAL_REQUEST =
    "GET_ALL_TESTIMONIAL_REQUEST";
export const GET_ALL_TESTIMONIAL_SUCCESS =
    "GET_ALL_TESTIMONIAL_SUCCESS";
export const GET_ALL_TESTIMONIAL_FAIL =
    "GET_ALL_TESTIMONIAL_FAIL";
