import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../../redux/action/AuthAction";
import { toast, ToastContainer } from "react-toastify";

const Registration = () => {
  const [first_name, setFirst_Name] = useState("");
  const [mid_name, setMid_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [age, setAge] = useState("");
  const [maritial_status, setMaritial_status] = useState("");
  const [reffer_by, setReffer_by] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");

  // sign up data show in console
  // console.log(state);

  // const {
  //   first_name,
  //   last_name,
  //   mid_name,
  //   email,
  //   mobile,
  //   password,
  //   confirm_password,
  //   gender,
  //   nationality,
  //   age,
  //   maritial_status,
  //   reffer_by,
  // } = state;

  // let navigate = useNavigate();
  let dispatch = useDispatch();

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setState({ ...state, [name]: value });
  //   // console.log("handlerInputChange");

  // };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === confirm_password) {
      if (email != null && mobile != null) {
        dispatch(
          register(
            first_name,
            mid_name,
            last_name,
            email,
            mobile,
            gender,
            nationality,
            age,
            maritial_status,
            reffer_by,
            password,
            confirm_password
          )
        );
      } else {
        toast.error("Email And Contact Number is Required");
      }
    } else {
      toast.error("Password And Confirm Password Did Not Match");
    }
  };
  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   if (email !== null && email !== "" && mobile !== null && mobile !== "") {
  //     // let formData = new FormData();
  //     // formData.append("first_name", first_name);
  //     // formData.append("last_name", last_name);
  //     // formData.append("mid_name", mid_name);
  //     // formData.append("email", email);
  //     // formData.append("mobile", mobile);
  //     // formData.append("password", password);
  //     // formData.append("confirm_password", confirm_password);
  //     // formData.append("gender", gender);
  //     // formData.append("nationality", nationality);
  //     // formData.append("age", age);
  //     // formData.append("maritial_status", maritial_status);
  //     // formData.append("reffer_by", reffer_by);
  //     dispatch(
  //       register(
  //         first_name,
  //         last_name,
  //         mid_name,
  //         email,
  //         password,
  //         confirm_password,
  //         mobile,
  //         gender,
  //         nationality,
  //         age,
  //         maritial_status,
  //         reffer_by
  //       )
  //     );
  //   } else {
  //     toast.error("Email And Contact Number is Required");
  //   }

  //   // navigate("/signin");
  // };
  return (
    <>
      <ToastContainer />
      <div className="container pt-5">
        <div className="row">
          <div className="col-lg-6 col-md-12 bg-light mx-auto shad">
            <h3 className="pt-4 text-center">Registration</h3>
            <hr />
            <form onSubmit={handleSubmit}>
              <div className="form-group d-flex pt-4">
                <div className="col-md-6">
                  <label htmlFor="first_name">First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="First Name"
                    required
                    name="first_name"
                    value={first_name}
                    onChange={(e) => setFirst_Name(e.target.value)}
                  />
                </div>
                <div className="col-md-6 left">
                  <label htmlFor="exampleInputEmail1">Middle Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Middle Name"
                    required
                    name="mid_name"
                    value={mid_name}
                    onChange={(e) => setMid_name(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group mt-3 d-flex">
                <div className="col-md-6 ">
                  <label htmlFor="exampleInputEmail1">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                    required
                    name="last_name"
                    value={last_name}
                    onChange={(e) => setLast_name(e.target.value)}
                  />
                </div>
                <div className="col-lg-6 left">
                  <label htmlFor="exampleInputEmail1">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group d-flex mt-3">
                <div className="col-md-6 ">
                  <label htmlFor="mobile">Phone Number</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Your Mobile Number"
                    name="mobile"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
                <div className="col-md-6 left">
                  <label htmlFor="inputState">Gender </label>
                  <select
                    class="form-control"
                    name="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option selected>Choose...</option>
                    <option value={0}>Male</option>
                    <option value={1}>Female</option>
                  </select>
                </div>
              </div>

              <div className="form-group d-flex">
                <div className="col-md-6">
                  <label htmlFor="exampleInputEmail1">Nationality</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nationality"
                    required
                    name="nationality"
                    value={nationality}
                    onChange={(e) => setNationality(e.target.value)}
                  />
                </div>
                <div className="col-md-6 left">
                  <label htmlFor="exampleInputEmail1">Age</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Age"
                    required
                    name="age"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group d-flex">
                <div className="col-md-6">
                  <label htmlFor="maritial_status">Maritial Status</label>
                  <select
                    id="inputState"
                    class="form-control"
                    name="maritial_status"
                    value={maritial_status}
                    onChange={(e) => setMaritial_status(e.target.value)}
                  >
                    <option selected>Choose...</option>
                    <option value={0}>Married</option>
                    <option value={1}>Unmarried</option>
                    <option value={2}>Other</option>
                  </select>
                </div>

                <div className="col-md-6 left">
                  <label htmlFor="exampleInputEmail1">Referal Code</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="REFR889"
                    name="reffer_by"
                    value={reffer_by}
                    onChange={(e) => setReffer_by(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group d-flex">
                <div className="col-lg-6 ">
                  <label htmlFor="exampleInputEmail1">Password</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="col-md-6 left">
                  <label htmlFor="exampleInputEmail1">confirm password</label>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="confirm password"
                    name="confirm_password"
                    value={confirm_password}
                    onChange={(e) => setConfirm_password(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group row mt-4 reg">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
              <div className="user pt-2 d-flex">
                <p>Do You have an account ?</p>
                <Link to="/signin">
                  <p className="link">Sign In </p>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;

// import React, { useState } from "react";
// import { useDispatch } from "react-redux";
// import { Link, useNavigate } from "react-router-dom";
// import { register } from "../../redux/action/AuthAction";
// import { toast, ToastContainer } from "react-toastify";

// const Registration = () => {
//   const [state, setState] = useState({
//     first_name: "",
//     last_name: "",
//     mid_name: "",
//     email: "",
//     mobile: "",
//     password: "",
//     confirm_password: "",
//     gender: "",
//     nationality: "",
//     age: "",
//     maritial_status: "",
//     reffer_by: "",
//   });

//   // sign up data show in console
//   // console.log(state);

//   const {
//     first_name,
//     last_name,
//     mid_name,
//     email,
//     mobile,
//     password,
//     confirm_password,
//     gender,
//     nationality,
//     age,
//     maritial_status,
//     reffer_by,
//   } = state;

//   let navigate = useNavigate();
//   let dispatch = useDispatch();

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setState({ ...state, [name]: value });
//     // console.log("handlerInputChange");
//     console.log(state);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     if (email !== null && email !== "" && mobile !== null && mobile !== "") {
//       let formData = new FormData();
//       formData.append("first_name", first_name);
//       formData.append("last_name", last_name);
//       formData.append("mid_name", mid_name);
//       formData.append("email", email);
//       formData.append("mobile", mobile);
//       formData.append("password", password);
//       formData.append("confirm_password", confirm_password);
//       formData.append("gender", gender);
//       formData.append("nationality", nationality);
//       formData.append("age", age);
//       formData.append("maritial_status", maritial_status);
//       formData.append("reffer_by", reffer_by);
//       dispatch(
//         register(
//           first_name,
//           last_name,
//           mid_name,
//           email,
//           password,
//           confirm_password,
//           mobile,
//           gender,
//           nationality,
//           age,
//           maritial_status,
//           reffer_by
//         )
//       );
//     } else {
//       toast.error("Email And Contact Number is Required");
//     }

//     // navigate("/signin");
//   };
//   return (
//     <>
//       <ToastContainer />
//       <div className="container pt-5">
//         <div className="row">
//           <div className="col-lg-6 col-md-12 bg-light mx-auto shad">
//             <h3 className="pt-4 text-center">Registration</h3>
//             <hr />
//             <form onSubmit={handleSubmit}>
//               <div className="form-group d-flex pt-4">
//                 <div className="col-md-6">
//                   <label htmlFor="first_name">First Name</label>
//                   <input
//                     type="text"
//                     class="form-control"
//                     placeholder="First Name"
//                     required
//                     name="first_name"
//                     value={first_name}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div className="col-md-6 left">
//                   <label htmlFor="exampleInputEmail1">Middle Name</label>
//                   <input
//                     type="text"
//                     class="form-control"
//                     placeholder="Middle Name"
//                     required
//                     name="mid_name"
//                     value={mid_name}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>
//               <div className="form-group mt-3 d-flex">
//                 <div className="col-md-6 ">
//                   <label htmlFor="exampleInputEmail1">Last Name</label>
//                   <input
//                     type="text"
//                     class="form-control"
//                     placeholder="Last Name"
//                     required
//                     name="last_name"
//                     value={last_name}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div className="col-lg-6 left">
//                   <label htmlFor="exampleInputEmail1">Email</label>
//                   <input
//                     type="email"
//                     class="form-control"
//                     placeholder="Email"
//                     name="email"
//                     value={email}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>

//               <div className="form-group d-flex mt-3">
//                 <div className="col-md-6 ">
//                   <label htmlFor="mobile">Phone Number</label>
//                   <input
//                     type="number"
//                     class="form-control"
//                     placeholder="Enter Your Mobile Number"
//                     name="mobile"
//                     value={mobile}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div className="col-md-6 left">
//                   <label htmlFor="inputState">Gender </label>
//                   <select
//                     class="form-control"
//                     name="gender"
//                     value={gender}
//                     onChange={handleInputChange}
//                   >
//                     <option selected>Choose...</option>
//                     <option value={0}>Male</option>
//                     <option value={1}>Female</option>
//                   </select>
//                 </div>
//               </div>

//               <div className="form-group d-flex">
//                 <div className="col-md-6">
//                   <label htmlFor="exampleInputEmail1">Nationality</label>
//                   <input
//                     type="text"
//                     class="form-control"
//                     placeholder="Nationality"
//                     required
//                     name="nationality"
//                     value={nationality}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div className="col-md-6 left">
//                   <label htmlFor="exampleInputEmail1">Age</label>
//                   <input
//                     type="number"
//                     class="form-control"
//                     placeholder="Age"
//                     required
//                     name="age"
//                     value={age}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>
//               <div className="form-group d-flex">
//                 <div className="col-md-6">
//                   <label htmlFor="maritial_status">Maritial Status</label>
//                   <select
//                     id="inputState"
//                     class="form-control"
//                     name="maritial_status"
//                     value={maritial_status}
//                     onChange={handleInputChange}
//                   >
//                     <option selected>Choose...</option>
//                     <option value={0}>Married</option>
//                     <option value={1}>Unmarried</option>
//                     <option value={2}>Other</option>
//                   </select>
//                 </div>

//                 <div className="col-md-6 left">
//                   <label htmlFor="exampleInputEmail1">Referal Code</label>
//                   <input
//                     type="text"
//                     class="form-control"
//                     placeholder="REFR889"
//                     name="reffer_by"
//                     value={reffer_by}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>
//               <div className="form-group d-flex">
//                 <div className="col-lg-6 ">
//                   <label htmlFor="exampleInputEmail1">Password</label>
//                   <input
//                     type="password"
//                     class="form-control"
//                     placeholder="Password"
//                     name="password"
//                     value={password}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div className="col-md-6 left">
//                   <label htmlFor="exampleInputEmail1">confirm password</label>
//                   <input
//                     type="password"
//                     class="form-control"
//                     placeholder="confirm password"
//                     name="confirm_password"
//                     value={confirm_password}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>

//               <div className="form-group row mt-4 reg">
//                 <button type="submit" className="btn btn-primary">
//                   Submit
//                 </button>
//               </div>
//               <div className="user pt-2 d-flex">
//                 <p>Do You have an account ?</p>
//                 <Link to="/signin">
//                   <p className="link">Sign In </p>
//                 </Link>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>

//     </>
//   );
// };

// export default Registration;
