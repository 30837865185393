import React from "react";
import { Link } from "react-router-dom";

const Otp = () => {
  const handleSubmit = () => {};
  return (
    <div>
      <div className="container pt-5">
        <div className="row">
          <div className="col-md-12 col-lg-4 mx-auto shad bg-light">
            <h3 className="text-center pt-4">OTP Verification</h3>
            <hr />
            <form onSubmit={handleSubmit}>
              <div class="form-group">
                <label htmlFor="exampleInputEmail1">OTP</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Verification Code"
                  name="email"
                  required
                  // value={email}
                  // onChange={handleInputChange}
                />
              </div>

              <div className="form-group row p-3 reg">
                <Link to="/">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
