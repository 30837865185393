import {

  GET_ALL_TAXI_REQUEST,
  GET_ALL_TAXI_SUCCESS,
  GET_ALL_TAXI_FAIL,


  SELECT_TAXI_REQUEST,
  SELECT_TAXI_SUCCESS,
  SELECT_TAXI_FAIL,


  // GET_SINGLE_TAXI_REQUEST,
  // GET_SINGLE_TAXI_SUCCESS,
  // GET_SINGLE_TAXI_FAIL,

  GET_SINGLE_ID_TAXI_REQUEST,
  GET_SINGLE_ID_TAXI_SUCCESS,
  GET_SINGLE_ID_TAXI_FAIL,

  
  SEARCH_TAXI_REQUEST,
  SEARCH_TAXI_SUCCESS,
  SEARCH_TAXI_FAIL,

  
  GET_FROM_LOC_REQUEST,
  GET_FROM_LOC_SUCCESS,
  GET_FROM_LOC_FAIL,



  UPDATE_SEARCH_TAXI_REQUEST,
  UPDATE_SEARCH_TAXI_SUCCESS,
  UPDATE_SEARCH_TAXI_FAIL,

  BOOKING_FORM_REQUEST,
  BOOKING_FORM_SUCCESS,
  BOOKING_FORM_FAIL,

  BUTTON_RADIO_REQUEST,
  BUTTON_RADIO_SUCCESS,
  BUTTON_RADIO_FAIL,

  PAYMENT_TAXI_REQUEST,
  PAYMENT_TAXI_SUCCESS,
  PAYMENT_TAXI_FAIL,

} from "../constant/TaxiBookingActionConstant"

const initialState = {
  loading: false,
  action: "data",
  result: [],
  data: [],
  response: {},
  book_res:[],
  singledata: [],
  msg: "",
  error: "",
};

const AddtionalFacilityReducer_Taxi = (state = initialState, action) => {
  switch (action.type) {

    case  GET_ALL_TAXI_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };

    case GET_ALL_TAXI_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_ALL_TAXI_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };


      // -----------button radio-------------

      case  BUTTON_RADIO_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };

    case BUTTON_RADIO_SUCCESS:
      return {
        ...state,
        data: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case BUTTON_RADIO_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };
// -----------button radio-------------


      // GET SELECT TAXI REQUEST

      case  SELECT_TAXI_REQUEST:
        return {
          ...state,
          loading: action.payload,
        };
  
      case SELECT_TAXI_SUCCESS:
        return {
          ...state,
          result: action.result.data,
          loading: action.payload,
          msg: action.msg,
        };
      case SELECT_TAXI_FAIL:
        return {
          ...state,
          error: action.error,
          loading: action.payload,
          msg: action.msg,
        };
  
      
        case SEARCH_TAXI_REQUEST:
          return {
            ...state,
            loading: action.payload,
          };
        case SEARCH_TAXI_SUCCESS:
          return {
            ...state,
            result: state.result.concat(action.result.data),
            response: action.result.msg,
            loading: action.payload,
            msg: action.msg,
          };


          case SEARCH_TAXI_FAIL:
          return {
            ...state,
            error: action.error,
            loading: action.payload,
            msg: action.msg,
          };



          // update search taxi request

          // case UPDATE_SEARCH_TAXI_REQUEST:
          //   return {
          //     ...state,
          //     loading: action.payload,
          //   };
          // case UPDATE_SEARCH_TAXI_SUCCESS:
          //   return {
          //     ...state,
          //     result: state.result.map((item)=>
          //       item.id===action.result.msg?action.result.data:item
          //       ),
          //     response: action.result.msg,
          //     loading: action.payload,
          //     msg: action.msg,
          //   };
          
          // case UPDATE_SEARCH_TAXI_FAIL:
          //   return {
          //     ...state,
          //     error: action.error,
          //     loading: action.payload,
          //     msg: action.msg,
          //   };

// 
// GET SINGLE TAXI POST REQUEST

//--------------------- taxi booking-----------------------------

  case BOOKING_FORM_REQUEST:
  return {
    ...state,
    loading: action.payload,
  };
case BOOKING_FORM_SUCCESS:
  return {
    ...state,
    book_res: state.book_res.concat(action.result.data),
    response: action.result.msg,
    loading: action.payload,
    msg: action.msg,
  };


  case BOOKING_FORM_REQUEST:
  return {
    ...state,
    error: action.error,
    loading: action.payload,
    msg: action.msg,
  };
// ------------------ taxi booking-----------------------------
  //------------------------------------- PAYMENT TAXI------------------------
//   case PAYMENT_TAXI_REQUEST:
//   return {
//     ...state,
//     loading: action.payload,
//   };
// case PAYMENT_TAXI_SUCCESS:
//   return {
//     ...state,
//     result: state.result.concat(action.result.data),
//     response: action.result.msg,
//     loading: action.payload,
//     msg: action.msg,
//   };


//   case PAYMENT_TAXI_FAIL:
//   return {
//     ...state,
//     error: action.error,
//     loading: action.payload,
//     msg: action.msg,
//   };
//------------------------- GET SINGLE ID TAXI------------------

// case GET_SINGLE_ID_TAXI_REQUEST:
//   return {
//     ...state,
//     loading: action.payload,
//   };
// case GET_SINGLE_ID_TAXI_SUCCESS:
//   return {
//     ...state,
//     result: state.result.concat(action.result.data),
//     response: action.result.msg,
//     loading: action.payload,
//     msg: action.msg,
//   };


//   case GET_SINGLE_ID_TAXI_FAIL:
//   return {
//     ...state,
//     error: action.error,
//     loading: action.payload,
//     msg: action.msg,
//   };
// ------------------------GET SINGLE ID TAXI---------------
  // ----------------------------------PAYMENT TAXI-----------------------------
// get_from_loc data city
         
case GET_FROM_LOC_REQUEST :
  return {
    ...state,
    loading: action.payload,
  };

case GET_FROM_LOC_SUCCESS:
  return {
    ...state,
    result: action.result.data,
    loading: action.payload,
    msg: action.msg,
  };
case GET_FROM_LOC_FAIL:
  return {
    ...state,
    error: action.error,
    loading: action.payload,
    msg: action.msg,
  };




    default:
      return state;
  }
};

export default AddtionalFacilityReducer_Taxi;
