// constant files

export const GET_ALL_TOURPACKAGES_REQUEST = "GET_ALL_TOURPACKAGES_REQUEST";
export const GET_ALL_TOURPACKAGES_SUCCESS = "GET_ALL_TOURPACKAGES_SUCCESS";
export const GET_ALL_TOURPACKAGES_FAIL = "GET_ALL_TOURPACKAGES_FAIL";

export const GET_SINGLE_TOURPACKAGES_REQUEST =
  "GET_SINGLE_TOURPACKAGES_REQUEST";
export const GET_SINGLE_TOURPACKAGES_SUCCESS =
  "GET_SINGLE_TOURPACKAGES_SUCCESS";
export const GET_SINGLE_TOURPACKAGES_FAIL = "GET_SINGLE_TOURPACKAGES_FAIL";

export const GET_TOURPACKAGES_IMAGE_REQUEST = "GET_TOURPACKAGES_IMAGE_REQUEST";
export const GET_TOURPACKAGES_IMAGE_SUCCESS = "GET_TOURPACKAGES_IMAGE_SUCCESS";
export const GET_TOURPACKAGES_IMAGE_FAIL = "GET_TOURPACKAGES_IMAGE_FAIL";


export const GET_TOURPACKAGES_DAYSCHEDULE_REQUEST = "GET_TOURPACKAGES_DAYSCHEDULE_REQUEST";
export const GET_TOURPACKAGES_DAYSCHEDULE_SUCCESS = "GET_TOURPACKAGES_DAYSCHEDULE_SUCCESS";
export const GET_TOURPACKAGES_DAYSCHEDULE_FAIL = "GET_TOURPACKAGES_DAYSCHEDULE_FAIL";


export const GET_TOURPACKAGES_ENQUERY_REQUEST = "GET_TOURPACKAGES_DAYSCHEDULE_REQUEST";
export const GET_TOURPACKAGES_ENQUERY_SUCCESS = "GET_TOURPACKAGES_DAYSCHEDULE_SUCCESS";
export const GET_TOURPACKAGES_ENQUERY_FAIL = "GET_TOURPACKAGES_DAYSCHEDULE_FAIL";