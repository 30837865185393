import React from "react";
import Navbar from "../src/component/share/Navbar";
import Footer from "../src/component/share/Footer";
import Home from "./screen/Home";
import HotelBooking from "./screen/HotelBooking";
import FlightBooking from "./screen/FlightBooking";
import HotelSingle from "./screen/HotelSingle";
import HolidayTour from "./screen/HolidayTour";
import TourPackage from "./screen/TourPackage";
import TaxiBooking from "./screen/TaxiBooking";
import SpecialOffer from "./screen/SpecialOffer";
import Terms from "./screen/Terms";
import Aboutus from "./screen/Aboutus";





import TaxiDetails from "./component/taxi/TaxiDetails";
import BookingForm from "./component/taxi/BookingForm";
import PaymentDetail from "./component/taxi/PaymentDetail";
import SignIn from "./component/Account/SignIn";
import Registration from "./component/Account/Registration";
import Forgot from "./component/Account/Forgot";
import Otp from "./component/Account/Otp";
import NewPassword from "./component/Account/NewPassword";
import Dashboard from "./component/DashBoard/Dashboard";
import Razorpay from './component/taxi/Razorpay';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactUs from "./component/ContactUs";
import CorporateEnquiry from "./component/CorporateEnquiry";

export default function Router() {
  return (

    <BrowserRouter>
      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/aboutus" element={<Aboutus />} />



        <Route path="/hotel-booking" element={<HotelBooking />} />
        <Route path="/hotel-detail/:id" element={<HotelSingle />} />

        <Route path="/tour-package" element={<HolidayTour />} />
        <Route path="/tour-product/:id" element={<TourPackage />} />


        <Route path="/BookingForm" element={<BookingForm />} />

        <Route path="/flight-booking" element={<FlightBooking />} />


        <Route path="/special-offer" element={<SpecialOffer />} />
        <Route path="/taxi-booking" element={<TaxiBooking />} />
        <Route path="/taxi-search" element={<TaxiDetails />} />
        <Route path="/BookingForm" element={<BookingForm />} />
        <Route path="/BookingForm/:taxiId" element={<BookingForm />} />

        <Route path="/PaymentDetail" element={<PaymentDetail />} />
        <Route path="/PaymentDetail/:id" element={<PaymentDetail />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/newpassword" element={<NewPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/corporateenquiry" element={<CorporateEnquiry />} />



      </Routes>


      <Footer />
    </BrowserRouter>

  );
}
