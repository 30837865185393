import {
  SEARCH_HOTEL_LIST_FAIL,
  SEARCH_HOTEL_LIST_REQUEST,
  SEARCH_HOTEL_LIST_SUCCESS,
  SEARCH_CITY_LIST_FAIL,
  SEARCH_CITY_LIST_REQUEST,
  SEARCH_CITY_LIST_SUCCESS,
} from "../constant/HotelListConstant";
import axios from "axios";
import { toast } from "react-toastify";

// Search Hotels
const searchHotelList = (
  city_id,
  city_name,
  check_in,
  check_out,
  no_rooms,
  no_adults,
  no_children
) => {
  return function (dispatch) {
    dispatch({
      type: SEARCH_HOTEL_LIST_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin/hotel-bycity/${city_name}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(searchHotelListPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: SEARCH_HOTEL_LIST_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.error(error.msg);
      });
  };
};
export const searchHotelListPre = (data) => {
  toast.success(data.msg);
  return {
    type: SEARCH_HOTEL_LIST_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// Search Cities
const searchCityList = (keyword) => {
  return function (dispatch) {
    dispatch({
      type: SEARCH_CITY_LIST_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin/city-list`,
      method: "POST",
      data: { keyword: keyword },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(searchCityListPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: SEARCH_CITY_LIST_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.error(error.msg);
      });
  };
};
export const searchCityListPre = (data) => {
  toast.success(data.msg);
  return {
    type: SEARCH_CITY_LIST_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export { searchHotelList, searchCityList };
