// constant files
export const GET_ALL_TAXI_REQUEST = "GET_ALL_TAXI_REQUEST";
export const GET_ALL_TAXI_SUCCESS = "GET_ALL_TAXI_SUCCESS";
export const GET_ALL_TAXI_FAIL    = "GET_ALL_TAXI_FAIL";

export const SEARCH_TAXI_REQUEST = "SEARCH_TAXI_REQUEST";
export const SEARCH_TAXI_SUCCESS = "SEARCH_TAXI_SUCCESS";
export const SEARCH_TAXI_FAIL    = "SEARCH_TAXI_FAIL";

export const GET_FROM_LOC_REQUEST = "GET_FROM_LOC_REQUEST";
export const GET_FROM_LOC_SUCCESS = "GET_FROM_LOC_SUCCESS";
export const GET_FROM_LOC_FAIL    = "GET_FROM_LOC_FAIL";

export const GET_SINGLE_ID_TAXI_REQUEST = "GET__SINGLE_ID_TAXI_REQUEST";
export const GET_SINGLE_ID_TAXI_SUCCESS = "GET__SINGLE_ID_TAXI_SUCCESS";
export const GET_SINGLE_ID_TAXI_FAIL    = "GET__SINGLE_ID_TAXI_FAIL";

export const UPDATE_SEARCH_TAXI_REQUEST = "UPDATESEARCH_TAXI_REQUEST";
export const UPDATE_SEARCH_TAXI_SUCCESS = "UPDATE_SEARCH_TAXI_SUCCESS";
export const UPDATE_SEARCH_TAXI_FAIL = "UPDATE_SEARCH_TAXI_FAIL";

export const DELETE_TAXI_REQUEST = "DELETE_TAXI_REQUEST";
export const DELETE_TAXI_SUCCESS = "DELETE_TAXI_SUCCESS";
export const DELETE_TAXI_FAIL = "DELETE_TAXI_FAIL";


export const SELECT_TAXI_REQUEST="SELECT_TAXI_REQUEST";
export const SELECT_TAXI_SUCCESS = "SELECT_TAXI_SUCCESS";
export const SELECT_TAXI_FAIL = "SELECT_TAXI_FAIL";

export const TAXI_CHANGE_STATUS_REQUEST = "TAXI_CHANGE_STATUS_REQUEST";
export const TAXI_CHANGE_STATUS_SUCCESS = "TAXI_CHANGE_STATUS_SUCCESS";
export const TAXI_CHANGE_STATUS_FAIL = "TAXI_CHANGE_STATUS_FAIL";


export const BOOKING_FORM_REQUEST="BOOKING_FORM_REQUEST";
export const BOOKING_FORM_SUCCESS="BOOKING_FORM_SUCCESS";
export const BOOKING_FORM_FAIL="BOOKING_FORM_FAIL";

export const BUTTON_RADIO_REQUEST="BUTTON_RADIO_REQUEST";
export const BUTTON_RADIO_SUCCESS="BUTTON_RADIO_SUCCESS";
export const BUTTON_RADIO_FAIL=   "BUTTON_RADIO_FAIL";


export const PAYMENT_TAXI_REQUEST="PAYMENT_TAXI_REQUEST";
export const PAYMENT_TAXI_SUCCESS="PAYMENT_TAXI_SUCCESS";
export const PAYMENT_TAXI_FAIL=   "PAYMENT_TAXI_FAIL";