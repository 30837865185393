import {
  ADD_BOOKINGROOM_FAIL,
  ADD_BOOKINGROOM_REQUEST,
  ADD_BOOKINGROOM_SUCCESS,
  DELETE_BOOKINGROOM_FAIL,
  DELETE_BOOKINGROOM_REQUEST,
  DELETE_BOOKINGROOM_SUCCESS,
  GET_ALL_BOOKINGROOM_FAIL,
  GET_ALL_BOOKINGROOM_REQUEST,
  GET_ALL_BOOKINGROOM_SUCCESS,
  GET_SINGLE_BOOKINGROOM_FAIL,
  GET_SINGLE_BOOKINGROOM_REQUEST,
  GET_SINGLE_BOOKINGROOM_SUCCESS,
  BOOKINGROOM_CHANGE_STATUS_FAIL,
  BOOKINGROOM_CHANGE_STATUS_REQUEST,
  BOOKINGROOM_CHANGE_STATUS_SUCCESS,
  UPDATE_BOOKINGROOM_FAIL,
  UPDATE_BOOKINGROOM_REQUEST,
  UPDATE_BOOKINGROOM_SUCCESS,
} from "../constant/BookingRoomConstant";

import axios from "axios";
import { toast } from "react-toastify";

const loadAllBookingRoom = () => {
  return function (dispatch) {
    dispatch({
      type: GET_ALL_BOOKINGROOM_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin/BOOKINGROOM/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        console.log("res", res);
        dispatch(loadAllBookingRoomPre(res.data));
      })
      .catch((error) => {
        // console.log("error", error);

        dispatch({
          type: GET_ALL_BOOKINGROOM_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllBookingRoomPre = (data) => {
  return {
    type: GET_ALL_BOOKINGROOM_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadSingleBookingRoom = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_SINGLE_BOOKINGROOM_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin/BOOKINGROOM/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(loadSingleBookingRoomPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: GET_SINGLE_BOOKINGROOM_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadSingleBookingRoomPre = (data) => {
  return {
    type: GET_SINGLE_BOOKINGROOM_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const createBookingRoom = (data) => {
  return function (dispatch) {
    dispatch({
      type: ADD_BOOKINGROOM_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/hotel/booking/`,
      method: "POST",
      data: data,

      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        console.log("res", res.data);
        dispatch(createBookingRoomPre(res.data));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: ADD_BOOKINGROOM_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.error(error.msg);
      });
  };
};
export const createBookingRoomPre = (data) => {
  toast.success(data.msg);
  return {
    type: ADD_BOOKINGROOM_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const updateBookingRoom = (data) => {
  return function (dispatch) {
    dispatch({
      type: UPDATE_BOOKINGROOM_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-BOOKINGROOM/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(updateBookingRoomPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_BOOKINGROOM_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger("Failed To Update The BookingRoom!");
      });
  };
};
export const updateBookingRoomPre = (data) => {
  toast.success("BookingRoom Updated Successfully!");

  return {
    type: UPDATE_BOOKINGROOM_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const deleteBookingRoom = (data) => {
  return function (dispatch) {
    dispatch({
      type: DELETE_BOOKINGROOM_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/BookingRoom1/admin-BOOKINGROOM-delete/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(deleteBookingRoomPre(res.data));
        // dispatch(loadAllBOOKINGROOM());
      })
      .catch((error) => {
        dispatch({
          type: DELETE_BOOKINGROOM_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const deleteBookingRoomPre = (data) => {
  toast.error("BookingRoom Deleted Successfully!");
  return {
    type: DELETE_BOOKINGROOM_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const BookingRoomChangeStatus = (data) => {
  return function (dispatch) {
    dispatch({
      type: BOOKINGROOM_CHANGE_STATUS_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-BOOKINGROOM-visible/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(BookingRoomChangeStatusPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: BOOKINGROOM_CHANGE_STATUS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const BookingRoomChangeStatusPre = (data) => {
  toast.success("BookingRoom changeStatus Successfully!");
  return {
    type: BOOKINGROOM_CHANGE_STATUS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export {
  loadAllBookingRoom,
  loadSingleBookingRoom,
  createBookingRoom,
  updateBookingRoom,
  deleteBookingRoom,
  BookingRoomChangeStatus,
};
