import React, { useState } from "react";
import { useDispatch } from "react-redux";

const UpdateProfile = () => {
  const [image, setImage] = useState([]);

  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    mid_name: "",
    email: "",
    mobile: "",
    password: "",
    gender: "",
    nationality: "",
    maritial_status: "",
  });

  const {
    first_name,
    last_name,
    mid_name,
    email,
    mobile,
    gender,
    nationality,
    maritial_status,
  } = state;

  let dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (email !== null) {
    //   let formData = new FormData();
    //   formData.append("image", image[0]);
    //   formData.append("first_name", first_name);
    //   formData.append("last_name", last_name);
    //   formData.append("mid_name", mid_name);
    //   formData.append("email", email);
    //   formData.append("mobile", mobile);
    //   formData.append("password", password);

    //   formData.append("gender", gender);
    //   formData.append("nationality", nationality);
    //   formData.append("age", age);
    //   formData.append("maritial_status", maritial_status);
    //   formData.append("reffer_by", reffer_by);
    //   dispatch(register(formData));
    // } else {
    //   toast.error("Email And Contact Number is Required");
    // }
  };

  return (
    <>
      <div className="pro">
        <h2>Update Profile</h2>
      </div>

      <div className="container-fluid pt-5">
        <div className="row">
          <div className="col-lg-12 col-md-12 bg-light shad">
            <form onSubmit={handleSubmit}>
              <div className="form-group d-flex pt-4">
                <div className="col-md-4">
                  <label htmlFor="first_name">First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="First Name"
                    required
                    name="first_name"
                    value={first_name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-4 left">
                  <label htmlFor="exampleInputEmail1">Middle Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Middle Name"
                    required
                    name="mid_name"
                    value={mid_name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-4 left">
                  <label htmlFor="exampleInputEmail1">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                    required
                    name="last_name"
                    value={last_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="form-group mt-3 d-flex">
                <div className="col-lg-6">
                  <label htmlFor="exampleInputEmail1">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 left">
                  <label htmlFor="icon" className="text-dark">
                    Image :
                  </label>
                  <input
                    alt="Category image"
                    type="file"
                    name="image"
                    className="form-control"
                    accept="image/*"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="form-group d-flex mt-3">
                <div className="col-md-6 ">
                  <label htmlFor="mobile">Phone Number</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Your Mobile Number"
                    name="mobile"
                    value={mobile}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 left">
                  <label htmlFor="inputState">Gender </label>
                  <select
                    class="form-control"
                    name="gender"
                    value={gender}
                    onChange={handleInputChange}
                  >
                    <option selected>Choose...</option>
                    <option value={0}>Male</option>
                    <option value={1}>Female</option>
                  </select>
                </div>
              </div>

              <div className="form-group d-flex">
                <div className="col-md-12 col-lg-6">
                  <label htmlFor="exampleInputEmail1">Nationality</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nationality"
                    required
                    name="nationality"
                    value={nationality}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-12 col-lg-6 left">
                  <label htmlFor="maritial_status">Maritial Status</label>
                  <select
                    id="inputState"
                    class="form-control"
                    name="maritial_status"
                    value={maritial_status}
                    onChange={handleInputChange}
                  >
                    <option selected>Choose...</option>
                    <option value={0}>Married</option>
                    <option value={1}>Unmarried</option>
                    <option value={2}>Other</option>
                  </select>
                </div>
              </div>

              <div className="form-group row p-4 reg">
                <button type="submit" className="btn btn-primary">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProfile;
