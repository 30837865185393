import React from "react";
import { Table } from "react-bootstrap";

const BookingHistory = () => {
  return (
    <>
      <div className="history">
        <h2>Booking History</h2>
      </div>
      <div className="container-fluid pt-5">
        <div className="row">
          <div className="col-md-12">
            <Table>
              <thead>
                <tr className="bgs">
                  <th>Sr.No</th>
                  <th>Booking ID</th>
                  <th>Total</th>
                  <th>Final Total</th>
                  <th>Booking Status</th>
                  <th>Booking Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingHistory;
