import React, { useState, useEffect } from "react";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { from_loc_taxi, ButtonRadio, SearchTaxi } from "../../redux/action/TaxiBookingAction"
import Select from 'react-select';


export default function TaxiService() {






  const [citylist, setCityList] = useState([]);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {

    dispatch(ButtonRadio());
    dispatch(from_loc_taxi())

  }, [])

  const Taxii = useSelector((state) => state.Taxi);

  const { result, data } = Taxii;


  useEffect(() => {

    console.log('res..', result[0]);


    var tempcitylist = result.map(item =>
      ({ value: item.id, label: item.name + "," + item.state })
    );
    //console.log('citylist..', citylist);
    setCityList(tempcitylist);

  }, [result])

  const [pickupCalender, setpickupCalender] = useState(false)
  const [ReturnCalender, setreturnCalender] = useState(false)



  const [triptype, setTriptype] = useState(1)
  const [triptypeName, setTriptypeName] = useState(1)

  const [oneway, setOneway] = useState(true);
  const [roundtrip, setRoundtrip] = useState(false);
  const [local, setLocal] = useState(false);
  const [airport, setAirport] = useState(false);

  const [frombooking, setfrombooking] = useState("")
  const [frombookingName, setfrombookingName] = useState("")

  const [tobooking, settobooking] = useState("")
  const [tobookingName, settobookingName] = useState("")

  const [pickupValue, setPickupValue] = useState(new Date());
  const [returnValue, setReturnValue] = useState(new Date());
  const [picTime, setPicTime] = useState("")

  const [airporttriptype, setAirporttriptype] = useState("")
  const [airpicaddress, setAirpicaddress] = useState("")





  const pickupCalenderchange = (date) => {
    //this.setState({ selectedDate: date }); 
    setPickupValue(date);
    setpickupCalender(false)
    console.log((date));
  }



  const returnCalenderchange = (date) => {
    //this.setState({ selectedDate: date }); 
    setReturnValue(date);
    setreturnCalender(false)
    console.log((date));
  }











  const submitHandler = (e) => {


    //console.log('frombooking', frombooking);
    e.preventDefault();
    // alert("pklodjfdijfhefdf")
    if (pickupValue !== "" && picTime !== "") {

      // alert("if")
      /*
      dispatch(
        SearchTaxi(
          fromLoc,
          toLoc,
          value,
          picTime
        )
      ) */
      var paramvalues = "from_city_name=" + frombookingName + "&to_city_name=" + tobookingName + "&from_city_id=" + frombooking + "&to_city_id=" + tobooking + "&pickdate=" + pickupValue.toLocaleDateString() + "&returndate=" + returnValue.toLocaleDateString() + "&pictime=" + picTime + "&airtriptype=" + airporttriptype + "&picaddress=" + airpicaddress + "&triptype=" + triptype + "&triptypename=" + triptypeName;
      navigate("/taxi-search?" + paramvalues)
    }
    else {
      alert("Please input all input Field!")

      // toast.warning("Please input all input Field!");

    }
  }

  // {data.map((item)=>{
  //   console.log(item.id,"id is")


  //   })}



  const onewayhandler = () => {
    setOneway(true)

    setLocal(false);
    setAirport(false);
    setRoundtrip(false);
    setTriptype(1);
    setTriptypeName("One Way");
  };
  const Roundtriphandler = () => {

    setRoundtrip(true);
    setOneway(false)
    setLocal(false);
    setAirport(false);
    setTriptype(2);
    setTriptypeName("Two Way");


  };


  const localhandler = () => {

    setLocal(true);

    setRoundtrip(false);
    setOneway(false)
    setAirport(false);
    setTriptype(3);
    setTriptypeName("Local");


  };

  const airporthandler = () => {
    setAirport(true);
    setLocal(false);
    setRoundtrip(false);
    setOneway(false)
    //  alert(data[1].id,"id 2")
    setTriptype(4);
    setTriptypeName("Airport");


  };


  return (
    <section className="flight-booking-form">
      <div className="container">


        <form>
          <div className="flight-booking-top">
            <div className={`book-fl ${oneway ? "selectbtn_taxi" : ""}`}>

              <label htmlFor="outStation" onClick={onewayhandler} >ONE WAY</label>
            </div>
            <div className={`book-fl ${roundtrip ? "selectbtn_taxi" : ""}`}>

              <label htmlFor="outStation" onClick={Roundtriphandler}>TWO WAY</label>
            </div>
            <div className={`book-fl ${local ? "selectbtn_taxi" : ""}`}>

              <label htmlFor="local" onClick={localhandler} >LOCAL</label>
            </div>
            <div className={`book-fl ${airport ? "selectbtn_taxi" : ""}`}>

              <label htmlFor="airport" onClick={airporthandler}>Airport</label>
            </div>
          </div>
          <div className="flight-booking-middle">
            {oneway ? (
              <div className="round-trip divtaxi">
                <div className="main-box">

                  <div className="form-field">
                    <label>FROM</label>

                    <Select
                      className="searchdropdown"
                      options={citylist}
                      onChange={(e) => {
                        setfrombooking(e.value);
                        setfrombookingName(e.label);

                      }}
                    />

                    { /*

                 <Select
                      name="frombooking"
                      value={frombooking}
                      onChange={(e) => {
                        setfrombooking(e.target.value)
                      }}
                    >
                      <option >Select</option>
                      {result.map((city) => {

                        return (
                          <>
                            <option value={city.name}>{city.name}</option>
                          </>
                        )
                      })}
                  </Select>

            */}


                  </div>
                  <div className="form-field">
                    <label>TO</label>
                    <Select
                      options={citylist}
                      onChange={(e) => {
                        settobooking(e.value)
                        settobookingName(e.label);

                      }}
                    />
                    {/*

                    
                    <Select
                      name="tobooking"
                      value={tobooking}
                      onChange={(e) => {
                        settobooking(e.target.value)
                      }}>
                      <option >Select</option>
                      {result.map((city) => {
                        return (
                          <>

                            <option value={city.name}>{city.name}</option>
                          </>
                        )
                      })}

                    </Select>
                    */}
                  </div>

                  <div className="form-field">
                    <label>PICK UP</label>
                    <input type="text" value={pickupValue.toLocaleDateString()} onClick={() => { setpickupCalender(!pickupCalender) }} />
                    {pickupCalender ? <Calendar onClickDay={pickupCalenderchange} /> : ""}
                  </div>

                  <div className="form-field">
                    <label>PICK UP AT</label>
                    <select
                      name="picTime"
                      value={picTime}
                      onChange={(e) => {
                        setPicTime(e.target.value)
                      }}
                    >
                      <option >Select</option>
                      <option>1:00 PM</option>
                      <option>2:00 PM</option>
                      <option>3:00 PM</option>
                      <option>4:00 PM</option>
                      <option>5:00 PM</option>

                    </select>
                  </div>

                </div>
              </div>
            ) : (
              ""
            )}
            {roundtrip ? (
              <div className="round-trip divtaxi">
                <div className="main-box">

                  <div className="form-field">
                    <label>FROM</label>
                    <Select
                      options={citylist}
                      onChange={(e) => {
                        setfrombooking(e.value)

                        setfrombookingName(e.label);
                      }}
                    />


                  </div>
                  <div className="form-field">
                    <label>TO</label>
                    <Select
                      options={citylist}
                      onChange={(e) => {
                        settobooking(e.value)
                        settobookingName(e.label);

                      }}
                    />

                  </div>

                  <div className="form-field">
                    <label>PICK UP</label>
                    <input type="text" value={pickupValue.toLocaleDateString()} onClick={() => { setpickupCalender(!pickupCalender) }} />
                    {pickupCalender ? <Calendar onClickDay={pickupCalenderchange} /> : ""}
                  </div>

                  <div className="form-field">
                    <label>RETURN</label>
                    <input type="text" value={returnValue.toLocaleDateString()} onClick={() => { setreturnCalender(!ReturnCalender) }} />
                    {ReturnCalender ? <Calendar onClickDay={returnCalenderchange} /> : ""}
                  </div>

                  <div className="form-field">
                    <label>PICK UP AT</label>
                    <select
                      name="picTime"
                      value={picTime}
                      onChange={(e) => {
                        setPicTime(e.target.value)
                      }}
                    >
                      <option >Select</option>
                      <option>1:00 PM</option>
                      <option>2:00 PM</option>
                      <option>3:00 PM</option>
                      <option>4:00 PM</option>
                      <option>5:00 PM</option>

                    </select>
                  </div>

                </div>
              </div>
            ) : (
              ""
            )}
            {local ? (
              <div className="one-way-trip divtaxi">
                <div className="main-box">
                  <div className="form-field">
                    <label>City</label>
                    <Select
                      options={citylist}
                      onChange={(e) => {
                        setfrombooking(e.value)

                        setfrombookingName(e.label);
                      }}
                    />


                  </div>
                  <div className="form-field">
                    <label>PICK UP</label>
                    <input type="text" value={pickupValue.toLocaleDateString()} onClick={() => { setpickupCalender(!pickupCalender) }} />
                    {pickupCalender ? <Calendar onClickDay={pickupCalenderchange} /> : ""}
                  </div>
                  <div className="form-field">
                    <label>Pick Up at</label>
                    <select
                      name="picTime"
                      value={picTime}
                      onChange={(e) => {
                        setPicTime(e.target.value)
                      }}
                    >
                      <option >Select</option>
                      <option>1:00 PM</option>
                      <option>2:00 PM</option>
                      <option>3:00 PM</option>
                      <option>4:00 PM</option>
                      <option>5:00 PM</option>

                    </select>
                  </div>

                </div>
              </div>
            ) : (
              ""
            )}
            {airport ? (
              <div className="one-way-trip divtaxi">
                <div className="main-box">
                  <div className="form-field">
                    <label>City</label>
                    <Select className="searchdropdown"
                      options={citylist}
                      onChange={(e) => {
                        setfrombooking(e.value)

                        setfrombookingName(e.label);
                      }}
                    />


                  </div>
                  <div className="form-field">
                    <label>Trip</label>
                    <select
                      name="airporttriptype"
                      value={airporttriptype}
                      onChange={(e) => {
                        setAirporttriptype(e.target.value)
                      }}
                    >
                      <option>Pickup from airport</option>
                      <option>Drop to airport</option>
                    </select>
                  </div>
                  <div className="form-field">
                    <label>Pick Up address</label>
                    <input type="text" name="airpicaddress" value={airpicaddress} onChange={(e) => {
                      setAirpicaddress(e.target.value)
                    }} />
                  </div>
                  <div className="form-field">
                    <label>Pick Up</label>
                    <input type="text" value={pickupValue.toLocaleDateString()} onClick={() => { setpickupCalender(!pickupCalender) }} />
                    {pickupCalender ? <Calendar onClickDay={pickupCalenderchange} /> : ""}

                  </div>
                  <div className="form-field">
                    <label>Pick Up at</label>
                    <select name="picTime"
                      value={picTime}
                      onChange={(e) => {
                        setPicTime(e.target.value)
                      }}>
                      <option>1:00 PM</option>
                      <option>2:00 PM</option>
                      <option>3:00 PM</option>
                      <option>4:00 PM</option>

                    </select>
                  </div>

                </div>
              </div>
            ) : (
              ""
            )}
            <div className="form-field-btn divbtntaxt">
              <button className="btn btn-warning btn-booking btnselectcar" onClick={submitHandler}>Select Car</button>
            </div>
          </div>


        </form>
      </div >
    </section >
  );
}
