import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { search_taxi_list } from "../../redux/action/TaxiBookingAction"
import $ from "jquery";
export default function TaxiDetails() {


	const [searchobject, setsearchobject] = useState()

	const [select_taxi, setselect_taxi] = useState([])





	const search = useLocation().search;

	const from_city_name = new URLSearchParams(search).get('from_city_name');
	const to_city_name = new URLSearchParams(search).get('to_city_name');
	const from_city_id = new URLSearchParams(search).get('from_city_id');
	const to_city_id = new URLSearchParams(search).get('to_city_id');
	const pickdate = new URLSearchParams(search).get('pickdate');
	const returndate = new URLSearchParams(search).get('returndate');
	const pictime = new URLSearchParams(search).get('pictime');
	const airtriptype = new URLSearchParams(search).get('airtriptype');
	const picaddress = new URLSearchParams(search).get('picaddress');
	const triptype = new URLSearchParams(search).get('triptype');
	const triptypename = new URLSearchParams(search).get('triptypename');
	console.log(from_city_name);

	const dispatch = useDispatch();

	useEffect(() => {

		let newData = {
			from_city_name: from_city_name,
			to_city_name: to_city_name,
			from_city_id: from_city_id,
			to_city_id: to_city_id,
			pickdate: pickdate,
			airtriptype: airtriptype,
			picaddress: picaddress,
			triptype: triptype,
			no_of_days: 1,
			triptypename: triptypename,
			pickup_time: pictime,
			returndate: returndate,


		};
		setsearchobject(newData);

		dispatch(search_taxi_list(newData))
	}, [])

	const select_taxircv = useSelector((state) => state.Taxi.result);

	useEffect(() => {

		if (select_taxircv.data) {
			//setselect_taxi(select_taxircv.data);
			//console.log('__', select_taxircv.data.length);
			setselect_taxi(select_taxircv.data);

		}
	}, [select_taxircv])

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);






	return (
		<>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>


				</Modal.Body>
				<Modal.Footer>
					<div className="form-field-btn">
						<Link to="/taxi-details">
							<button className="btn btn-warning btn-booking" >
								Select Carss
							</button>
						</Link>
					</div>
				</Modal.Footer>
			</Modal>



			<div className="container1">
				<div className="row ">
					<div className="box-4 ">
						<div className="col-md-4">
							<p className=" detail-text ps-5">
								{from_city_name}
								{
									to_city_name != "" ? (" to " + to_city_name) : ""

								}


							</p>
						</div>
						<div className="col-md-2">
							<p className="  detail-text content-text">
								PICKUP- {pickdate}
							</p>
						</div>
						<div className="col-md-2">
							<p className="  detail-text content-text ps-5">TIME- {pictime}</p>
						</div>
						<div className="col-md-4 ">
							{/* 
							<button
								className="btn btn-warning "
								onClick={handleShow}
							>
								Modify
							</button>*/}
						</div>
					</div>
				</div>



				<div className="row">

					{
						select_taxi.map((show) => {
							return (
								<><div className="box d-flex shadow-lg p-3 mb-2 bg-body">
									<div className="col-md-3 ">
										<div>
											<img className="imgtxi" src={show.icon}></img>
											<span>{show.name}</span>
										</div>
										<div className="aliasname">
											<span>{show.alias_name} ({show.no_of_seats} Seat)</span>

										</div>
									</div>
									<div className="col-md-3 content-1">

										<div>

											<span className="content-text">Distance: {show.distance}</span>
										</div>
									</div>
									<div className="col-md-3 content-1">

										<div>


											<span className="content-text">Extra Rate : {show.ppkm}/KM</span>

										</div>
									</div>
									<div className="col-md-1 content-1">
										<div>
											<span className="content-text" id="fare">{show.totalfare} INR</span>
										</div>
									</div>

									<div className="col-md-2 content-1">
										<div className="form-field-btn" key={show.id}>
											<Link to={`/BookingForm`} state={{ fromselect: show, fromdistance: searchobject }} >
												<button onClick={() => sessionStorage.setItem('id', show.id,) + sessionStorage.setItem('fare', show.totalfare,)} className="btn btn-warning btn-booking">
													Select Car
												</button>

											</Link>
										</div>
									</div>

								</div>

								</>
							)
						})
					}

				</div>



				<div className="row g-0 d-flex justify-content-center pt-5">
					<div className="d-flex justify-content-center banner-img">
						<img src="https://travelkit.savaari.com/ImageFolder/1642598109select-car-covid-banner.jpg"></img>
					</div>
				</div>
			</div>
		</>
	);
}
