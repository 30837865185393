import React, { useState, useEffect } from "react";
import hotel1 from "../../../image/hotel1.jpg"


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TourPackageImages } from "../../../redux/action/TourPackagesAction"

import { useParams } from "react-router-dom";

export default function MainImage() {


    const dispatch = useDispatch();
    let { id } = useParams();

    useEffect(() => {
        if (id) {
            dispatch(TourPackageImages(id));
        }

    }, [])

    const Tours = useSelector((state) => state.TourPackages);

    const { resultimages } = Tours;


    return (
        <section className='hotelimage'>
            <div className='container'>
                <Carousel autoPlay={true} showArrows Interval="50"
                    showThumbs={false}>
                    {resultimages.map((data) => {
                        return (
                            <>

                                <div>
                                    <img src={data.image} />
                                </div>
                            </>
                        )
                    })}
                </Carousel>


            </div>
        </section>
    );


}


