import { combineReducers } from "redux";
import HotelReducer from "./HotelReducer";
import HotelImageReducer from "./HotelImageReducer";
import AddtionalFacilityReducer from "./AddtionalFacilityReducer";
import FaqReducer from "./FaqReducer";
import TourPackagesReducer from "./TourPackagesReducer";
import HotelListReducer from "./HotelListReducer";
import RoomReducer from "./RoomReducer";
import BookingRoomReducer from "./BookingRoomReducer";
import AuthReducer from "./AuthReducer";
import AddtionalFacilityReducer_Taxi from "./TaxiBookingActionReducer";
import WebsiteReducer from "./WebsiteReducer";

;
const rootReducer = combineReducers({

  Hotel: HotelReducer,
  HotelImage: HotelImageReducer,
  AddtionalFacility: AddtionalFacilityReducer,
  Faq: FaqReducer,
  TourPackages: TourPackagesReducer,
  HotelList: HotelListReducer,
  Room: RoomReducer,
  BookingRoom: BookingRoomReducer,
  Auth: AuthReducer,
  Taxi: AddtionalFacilityReducer_Taxi,
  Website: WebsiteReducer


});

export default rootReducer;
