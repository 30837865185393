import {

  GET_ALL_TAXI_REQUEST,
  GET_ALL_TAXI_SUCCESS,
  GET_ALL_TAXI_FAIL,

  SELECT_TAXI_REQUEST,
  SELECT_TAXI_SUCCESS,
  SELECT_TAXI_FAIL,

  SEARCH_TAXI_REQUEST,
  SEARCH_TAXI_SUCCESS,
  SEARCH_TAXI_FAIL,

  GET_FROM_LOC_REQUEST,
  GET_FROM_LOC_SUCCESS,
  GET_FROM_LOC_FAIL,

  GET_SINGLE_ID_TAXI_REQUEST,
  GET_SINGLE_ID_TAXI_SUCCESS,
  GET_SINGLE_ID_TAXI_FAIL,

  UPDATE_SEARCH_TAXI_REQUEST,
  UPDATE_SEARCH_TAXI_SUCCESS,
  UPDATE_SEARCH_TAXI_FAIL,

  BOOKING_FORM_REQUEST,
  BOOKING_FORM_SUCCESS,
  BOOKING_FORM_FAIL,

  BUTTON_RADIO_REQUEST,
  BUTTON_RADIO_SUCCESS,
  BUTTON_RADIO_FAIL,

  PAYMENT_TAXI_REQUEST,
  PAYMENT_TAXI_SUCCESS,
  PAYMENT_TAXI_FAIL,



} from "../constant/TaxiBookingActionConstant"

import axios from "axios";
import { toast } from "react-toastify";


const loadAllAddtionalFacility_Taxi = () => {
  return function (dispatch) {
    dispatch({
      type: GET_ALL_TAXI_REQUEST,
      payload: true,
    });

    let OPTION = {
      // /api/v1/user/get-city-list
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user/get-city-list`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {

        dispatch(loadAllAddtionalFacilityPre_Taxi(res.data));

      })
      .catch((error) => {
        // console.log("error", error);

        dispatch({
          type: GET_ALL_TAXI_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};

//console.log(process.env.REACT_APP_API_PATH, "environment", process.env.REACT_APP_PROJECT_ENV)
// console.log(`${process.env.REACT_APP_API_PATH}/api/v1/vendor/ride-start`,"gggggggggggggggg")



export const loadAllAddtionalFacilityPre_Taxi = (data) => {
  return {
    type: GET_ALL_TAXI_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};


// SINGLE SELECT TAXI GET DATA FOR RIDE

const search_taxi_list = (newData) => {
  return function (dispatch) {

    dispatch({
      type: SELECT_TAXI_REQUEST,
      payload: true,
    });


    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/taxi/gettaxitypelist`,
      method: "POST",
      data: newData,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        //  alert("select taxi")
        dispatch(select_Single_TaxiPre(res.data));
        // console.log("select_single_Taxi", res.data);
      })
      .catch((error) => {
        // console.log("error", error);

        dispatch({
          type: SELECT_TAXI_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};


export const select_Single_TaxiPre = (data) => {
  return {
    type: SELECT_TAXI_SUCCESS,
    result: data,
    payload: false,
    msg: "SELECT_SINGLE_TAXI REQUEST SUCCESS",
  };
};


// from location get data------------


//------------ -----------------RADIO BUTTON --------------------------------------


const ButtonRadio = () => {
  return function (dispatch) {
    dispatch({
      type: BUTTON_RADIO_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user/gettriptype`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {

        dispatch(ButtonRadioPre(res.data));

      })
      .catch((error) => {
        // console.log("error", error);

        dispatch({
          type: BUTTON_RADIO_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};


export const ButtonRadioPre = (data) => {
  return {
    type: BUTTON_RADIO_SUCCESS,
    result: data,
    payload: false,
    msg: "BUTTON_RADIO_REQUEST SUCCESS",
  };
};

//---------------------------- RADIO BUTTON -------------------------------

// from_loc_taxi


const from_loc_taxi = () => {
  return function (dispatch) {

    dispatch({
      type: GET_FROM_LOC_REQUEST,
      payload: true,
    });

    let OPTION = {

      url: `${process.env.REACT_APP_API_PATH}/api/v1/user/get-city-list`,

      method: "GET",
      headers: {
        "content-type": "application/json",
      },

    };

    console.log("url_..", OPTION);


    axios(OPTION)
      .then((res) => {
        // alert("hi")
        dispatch(from_loc_taxiPre(res.data));
      })
      .catch((error) => {
        // console.log("error", error);

        dispatch({
          type: GET_FROM_LOC_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};


export const from_loc_taxiPre = (data) => {
  return {
    type: GET_FROM_LOC_SUCCESS,
    result: data,
    payload: false,
    msg: "SELECT_SINGLE_TAXI REQUEST SUCCESS",
  };
};

// SEARCH POST REQUEST TAXI FOR DRIVE-------------------

const SearchTaxi = (
  fromLoc,
  toLoc,
  value,
  picTime) => {

  return function (dispatch) {
    console.log("Handle on submit in action data", fromLoc, toLoc, value, picTime)
    dispatch({

      type: SEARCH_TAXI_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/vendor/ride-start`,
      method: "POST",
      data: {

        fromloc: fromLoc,
        toloc: toLoc,
        date: value,
        time: picTime
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(seacrhTaxiPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: SEARCH_TAXI_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger("Failed To Search Taxi !");
      });
  };
};

export const seacrhTaxiPre = (data) => {
  toast.success("Taxi find Successfully!");
  return {
    type: SEARCH_TAXI_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

// update search taxi details 

const updateSearchTaxi = (
  fromLoc,
  toLoc,
  value,
  picTime) => {

  return function (dispatch) {
    console.log("AAAA", fromLoc, toLoc, value, picTime)
    dispatch({

      type: UPDATE_SEARCH_TAXI_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/vendor/create-ride`,
      method: "POST",
      data: {

        fromloc: fromLoc,
        toloc: toLoc,
        date: value,
        time: picTime
      },
      headers: {
        "content-type": "application/json",
      },
    };


    axios(OPTION)
      .then((res) => {
        dispatch(updateSearchTaxiPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_SEARCH_TAXI_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger("Failed To Search Taxi !");
      });
  };
};

export const updateSearchTaxiPre = (data) => {
  toast.success("Taxi find Successfully!");
  return {
    type: UPDATE_SEARCH_TAXI_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

//----------------------- BOOKING FORM  POST REQUEST----------------------------

const BookingPostTaxi = (datarcv) => {


  return function (dispatch) {
    // alert("data has come from ")
    // console.log("form data from action js")
    dispatch({
      type: BOOKING_FORM_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/taxi/createtaxibooking`,
      method: "POST",
      data: datarcv,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        toast.success("Booked Successfully!");
        dispatch(BookingPostTaxiPre(res.data));
        // console.log(res.data,"booing form response")
      })
      .catch((error) => {
        dispatch({
          type: BOOKING_FORM_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });

      });
  };
};

export const BookingPostTaxiPre = (data) => {
  toast.success("Taxi find Successfully!");
  return {
    type: BOOKING_FORM_SUCCESS,
    result: data,
    payload: false,
    msg: "Create Taxi Successful",
  };
};

//--------------------------- BOOKING FORM  POST REQUEST---------------------------
// ----------------------------PAYMENT TAXI REQUEST------------------------
const PaymentTaxi = (id) => {

  return function (dispatch) {
    // alert("data has come from ")
    console.log("booking HAS COME form data", id)
    dispatch({
      type: PAYMENT_TAXI_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user/updateTaxibookingpaymentstatus`,
      method: "POST",
      data: {

        id: id
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        // alert("payment response")
        dispatch(PaymentTaxiPre(res.data));
        // console.log(res.data,"PAYMENT RESPONSE in actions")
      })
      .catch((error) => {
        dispatch({
          type: PAYMENT_TAXI_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });

      });
  };
};

export const PaymentTaxiPre = (data) => {
  toast.success("Taxi find Successfully!");
  return {
    type: PAYMENT_TAXI_SUCCESS,
    result: data,
    payload: false,
    msg: "PAYMENT SUCCESS",
  };
};

// ----------------------------PAYMENT TAXI REQUEST----------------------------


// -----------------------------SINGLE TAXI POST REQUEST -----------------


const single_taxi = (
  id
) => {

  return function (dispatch) {
    console.log("Handle on submit in action data", id)
    dispatch({

      type: GET_SINGLE_ID_TAXI_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/user/gettaxitypelist/${id}`,
      method: "POST",
      data: {

        id: id
      },
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        alert("params id")
        dispatch(single_taxi_pre(res.data));
        // console.log(res.data,"params data")
      })
      .catch((error) => {
        dispatch({
          type: GET_SINGLE_ID_TAXI_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger("Failed To Search Taxi !");
      });
  };
};



export const single_taxi_pre = (data) => {
  toast.success("Taxi find Successfully!");
  return {
    type: GET_SINGLE_ID_TAXI_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export { loadAllAddtionalFacility_Taxi, SearchTaxi, from_loc_taxi, updateSearchTaxi, search_taxi_list, single_taxi, BookingPostTaxi, ButtonRadio, PaymentTaxi };
