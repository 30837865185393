import React from 'react'
import banner from "../../image/offer-banner.jpg"


export default function OfferBanner() {
  return (
    <section class="inner-banner">
    <img src={banner} alt=""/>
        <div class="container">
        <div class="banner-content">
        <h2 class="title">Exclusive Offer of Truedeal</h2>

            </div>
        </div>


    </section>
  )
}

