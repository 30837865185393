import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import {
  // BookingFormTaxi,
  single_taxi,
  BookingPostTaxi,
} from "../../redux/action/TaxiBookingAction";
import { useParams } from "react-router-dom";
import axios from "axios";
export default function BookingForm(props) {

  const dispatchRequestSubmit = useDispatch();

  const location = useLocation()
  const { fromselect, fromdistance } = location.state

  console.log('...fromselect..', fromselect)
  console.log('...fromdistance..', fromdistance)

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [picaddress, setPicaddress] = useState("");
  const [dropaddress, setDropaddress] = useState("");




  // console.log(props.state,"props data ")
  const params = useParams();

  const { taxiId } = params;
  // console.log(taxiId, "use params ");

  let navigate = useNavigate();
  const dispatch = useDispatch();




  useEffect(() => {

  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    var tat = 0;
    if (fromdistance.trip_type == 4) {
      tat = 1;
    }
    if (email != null && mobile != null) {

      var datarcv = {
        trip_name: fromdistance.triptypename,
        trip_type: fromdistance.triptype,
        trip_airport_type: tat,
        name: name,
        email: email,
        contact: mobile,
        pickup_address: picaddress,
        drop_address: dropaddress,
        taxytype: fromselect.name,
        taxytype_id: fromselect.id,
        distance: fromselect.distance,
        price_km: fromselect.ppkm,
        tot_price: fromselect.totalfare,
        pickup_city: fromdistance.from_city_name,
        drop_city: fromdistance.to_city_name,
        pickup_date: fromdistance.pickdate,
        pickup_time: fromdistance.pickup_time,
        returndate: fromdistance.returndate,

      }


      console.log('datarcv', datarcv);
      dispatchRequestSubmit(BookingPostTaxi(datarcv));

      setTimeout(() => {

        alert("Your Request has been sent");
        setName('');
        setMobile('');

        setEmail('');
        setPicaddress('');
        setDropaddress('');

        // dispatchDays(BookingPostTaxi(""));
        // navigate("/PaymentDetail");


      }, 1000);


    } else {
      toast.error("Email And Contact Number is Required");
    }
  };


  return (
    <div className="container-booking">
      <div className="row">
        <div
          className="col-md-12"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <div className="col-md-5 ">
            <div className="box-booking p-2">
              <form onSubmit={handleSubmit}>

                <div className="row d-flex justify-content-center">
                  <div className="col-md-8">
                    <p className="booking-title">Contact & Pickup Details</p>
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="exampleInputEmail1">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Full Name"
                      required
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12 ">
                    <label htmlFor="mobile">Phone Number</label>
                    <input
                      type="number"
                      required
                      class="form-control"

                      placeholder="Enter Your Mobile Number"
                      name="mobile"
                      value={mobile}
                      onChange={(e) => {
                        if (e.target.value.length == 11) return false;
                        setMobile(e.target.value)
                      }}
                    />
                  </div>

                  <div className="col-md-12 ">

                    <label htmlFor="mobile">Email </label>
                    <input
                      type="email"
                      class="form-control"
                      required
                      placeholder="Enter Your Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="col-md-12 ">

                    <label htmlFor="mobile">Pickup Address </label>
                    <textarea
                      class="form-control"
                      placeholder="Comment"
                      name="picaddress"
                      required

                      value={picaddress}
                      onChange={(e) => setPicaddress(e.target.value)}
                    />
                  </div>

                  <div className="col-md-12 ">

                    <label htmlFor="mobile">Drop Address </label>
                    <textarea
                      class="form-control"
                      placeholder="Comment"
                      name="dropaddress"
                      required

                      value={dropaddress}
                      onChange={(e) => setDropaddress(e.target.value)}
                    />
                  </div>
                  <div className="col-md-12">
                    <p className="booking-footer-text">
                      {/* 
                      Send me trip updates on WhatsApp

                       */}
                    </p>
                  </div>
                  <div className="form-field-btn d-flex justify-content-center">
                    {/* <Link to={`/BookingForm/${taxiId}`} > */}
                    <button
                      type="submit"
                      className="btn btn-warning btn-booking mb-4 "

                    >
                      Proceed
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-4 pt-5">
            <Card style={{ width: "30rem", marginBottom: "2%" }}>
              <Card.Body>
                <Card.Title>Promise Of Quality</Card.Title>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <img
                    src="https://www.savaari.com/assets/img/bookingpage-images/our-drivers-text.png"
                    style={{ height: "100px", width: "100px" }}
                  ></img>
                  <img
                    src="https://www.savaari.com/assets/img/bookingpage-images/rating-text.png"
                    style={{ height: "100px", width: "100px" }}
                  ></img>
                  <img
                    src="https://www.savaari.com/assets/img/bookingpage-images/vintage.png"
                    style={{ height: "100px", width: "100px" }}
                  ></img>
                </div>
              </Card.Body>
            </Card>

            <Card style={{ width: "30rem" }}>
              <Card.Body>
                <Card.Title>Your Booking Details</Card.Title>
                <div
                  className="col-md-12"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <div className="col-md-6">
                    <p className="p-text">Trip:</p>
                    <p className="p-text">Trip Type:</p>
                    <p className="p-text">Pickup Date :</p>
                    <p className="p-text">Car Type :</p>
                    <p className="p-text">Total Fare :</p>
                  </div>

                  <div className="col-md-6" >
                    <p className="p-text">
                      {fromdistance.from_city_name}
                      {
                        fromdistance.to_city_name != "" ? (" to " + fromdistance.to_city_name) : ""

                      }
                    </p>
                    <p className="p-text">{fromdistance.triptypename}</p>


                    <p className="p-text">{fromdistance.pickdate}</p>
                    <p className="p-text"> {fromselect.name}</p>
                    <p className="p-text" >{fromselect.totalfare}</p>
                  </div>



                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
