import {
  GET_ALL_TOURPACKAGES_FAIL,
  GET_ALL_TOURPACKAGES_REQUEST,
  GET_ALL_TOURPACKAGES_SUCCESS,
  GET_SINGLE_TOURPACKAGES_FAIL,
  GET_SINGLE_TOURPACKAGES_REQUEST,
  GET_SINGLE_TOURPACKAGES_SUCCESS,
  GET_TOURPACKAGES_IMAGE_REQUEST,
  GET_TOURPACKAGES_IMAGE_SUCCESS,
  GET_TOURPACKAGES_IMAGE_FAIL,
  GET_TOURPACKAGES_DAYSCHEDULE_REQUEST,
  GET_TOURPACKAGES_DAYSCHEDULE_SUCCESS,
  GET_TOURPACKAGES_DAYSCHEDULE_FAIL,
  GET_TOURPACKAGES_ENQUERY_REQUEST,
  GET_TOURPACKAGES_ENQUERY_SUCCESS,
  GET_TOURPACKAGES_ENQUERY_FAIL,
} from "../constant/TourPackagesConstant";

import axios from "axios";
import { toast, ToastContainer } from "react-toastify";


const loadAllTourPackages = (datarcv) => {
  return function (dispatch) {
    dispatch({
      type: GET_ALL_TOURPACKAGES_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/tour/tour-package?` + datarcv,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(loadAllTourPackagesPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_TOURPACKAGES_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllTourPackagesPre = (data) => {
  return {
    type: GET_ALL_TOURPACKAGES_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};
const loadSingleTourPackages = (id) => {

  return function (dispatch) {
    dispatch({
      type: GET_SINGLE_TOURPACKAGES_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/tour/tour-package/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        //console.log("GET_SINGLE_TOURPACKAGES_SUCCESS", res);
        dispatch(loadSingleTourPackagesPre(res.data));
      })
      .catch((error) => {
        console.log("error", error);

        dispatch({
          type: GET_SINGLE_TOURPACKAGES_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadSingleTourPackagesPre = (data) => {
  return {
    type: GET_SINGLE_TOURPACKAGES_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};


const TourPackageImages = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_TOURPACKAGES_IMAGE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/tour/user-tour-imagesbytourid/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        // console.log("ffres", res);
        dispatch(TourPackageImagesPre(res.data));
      })
      .catch((error) => {
        console.log("error", error);

        dispatch({
          type: GET_TOURPACKAGES_IMAGE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const TourPackageImagesPre = (data) => {
  return {
    type: GET_TOURPACKAGES_IMAGE_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};


const TourPackageDauschedule = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_TOURPACKAGES_DAYSCHEDULE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/tour/user-tour-daywise-itinearybytourid/${id}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        // console.log("ffres", res);
        dispatch(TourPackageDauschedulePre(res.data));
      })
      .catch((error) => {
        console.log("error", error);

        dispatch({
          type: GET_TOURPACKAGES_DAYSCHEDULE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const TourPackageDauschedulePre = (data) => {
  return {
    type: GET_TOURPACKAGES_DAYSCHEDULE_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};


const submittourenquiry = (
  name,
  email,
  mobile,
  enquiry,
  pakage_name,
  pakage_id,
) => {
  return function (dispatch) {
    dispatch({
      type: GET_TOURPACKAGES_ENQUERY_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/tour/tour-package-enquiry`,
      method: "POST",
      data: {
        name,
        email,
        mobile,
        enquiry,
        pakage_name,
        pakage_id,
      },

      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        //console.log(res, "newdata");
        dispatch(submittourenquiryPre(res.data));
        //localStorage.setItem("userInfo", JSON.stringify(res.data));
        //window.location.href = "/#/signin";
      })

      .catch((error) => {
        dispatch({
          type: GET_TOURPACKAGES_ENQUERY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to create REQUEST",
        });
        toast.error(error.msg);
      });
  };
};

export const submittourenquiryPre = (data) => {
  toast.success(data.msg);
  return {
    type: GET_TOURPACKAGES_ENQUERY_SUCCESS,
    result: data,
    payload: false,
    msg: data.msg,
  };
};
export { loadAllTourPackages, loadSingleTourPackages, TourPackageImages, TourPackageDauschedule, submittourenquiry };
