import {
  ADD_BOOKINGROOM_FAIL,
  ADD_BOOKINGROOM_REQUEST,
  ADD_BOOKINGROOM_SUCCESS,
  DELETE_BOOKINGROOM_FAIL,
  DELETE_BOOKINGROOM_REQUEST,
  DELETE_BOOKINGROOM_SUCCESS,
  GET_ALL_BOOKINGROOM_FAIL,
  GET_ALL_BOOKINGROOM_REQUEST,
  GET_ALL_BOOKINGROOM_SUCCESS,
  GET_SINGLE_BOOKINGROOM_FAIL,
  GET_SINGLE_BOOKINGROOM_REQUEST,
  GET_SINGLE_BOOKINGROOM_SUCCESS,
  BOOKINGROOM_CHANGE_STATUS_FAIL,
  BOOKINGROOM_CHANGE_STATUS_REQUEST,
  BOOKINGROOM_CHANGE_STATUS_SUCCESS,
  UPDATE_BOOKINGROOM_FAIL,
  UPDATE_BOOKINGROOM_REQUEST,
  UPDATE_BOOKINGROOM_SUCCESS,
} from "../constant/BookingRoomConstant";

const initialState = {
  loading: false,
  action: "BOOKINGROOM",
  result: [],
  response: {},
  singledata: [],
  msg: "",
  error: "",
};

const BookingRoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_BOOKINGROOM_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_ALL_BOOKINGROOM_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_ALL_BOOKINGROOM_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case DELETE_BOOKINGROOM_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case DELETE_BOOKINGROOM_SUCCESS:
      return {
        ...state,
        // result: state.result.filter(
        //   (item) => item.id !== action.result.data.id
        result: state.result.map((item) =>
          item.id === action.result.data.id ? action.result.data : item
        ),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case DELETE_BOOKINGROOM_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case ADD_BOOKINGROOM_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_BOOKINGROOM_SUCCESS:
      return {
        ...state,
        result: state.result.concat(action.result.data),
        response: action.result.msg,
        loading: action.payload,
        msg: action.msg,
      };
    case ADD_BOOKINGROOM_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case UPDATE_BOOKINGROOM_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case UPDATE_BOOKINGROOM_SUCCESS:
      return {
        ...state,
        result: state.result.map((item) =>
          item.id === action.result.data.id ? action.result.data : item
        ),
        response: action.result.msg,
        singledata: [],
        loading: action.payload,

        msg: action.msg,
      };
    case UPDATE_BOOKINGROOM_FAIL:
      return {
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case BOOKINGROOM_CHANGE_STATUS_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case BOOKINGROOM_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        result: state.result.map((item) =>
          item.id === action.result.data.id ? action.result.data : item
        ),
        response: action.result.msg,
        singledata: [],
        loading: action.payload,

        msg: action.msg,
      };
    case BOOKINGROOM_CHANGE_STATUS_FAIL:
      return {
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case GET_SINGLE_BOOKINGROOM_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_SINGLE_BOOKINGROOM_SUCCESS:
      return {
        ...state,
        singledata: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_SINGLE_BOOKINGROOM_FAIL:
      return {
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default BookingRoomReducer;
