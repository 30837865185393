import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../share/Loader";
import Message from "../share/Message";
import { loadAllTourPackages } from "../../redux/action/TourPackagesAction"


export default function SideFilter(props) {

    const dispatch = useDispatch();

    const pricecheckBoxList = [
        {
            sprice: "0",
            eprice: "9999",
            checkstatus: false,
        },
        {
            sprice: "10000",
            eprice: "19999",
            checkstatus: false,
        },
        {
            sprice: "20000",
            eprice: "39990",
            checkstatus: false,
        },
        {
            sprice: "30000",
            eprice: "49999",
            checkstatus: false,
        },
        {
            sprice: "50000",
            eprice: "249999",
            checkstatus: false,
        },
    ];
    const [checkedStatePrice, setCheckedStatePrice] = useState(pricecheckBoxList);

    const handleChange = (e) => {
        const checked = e.target.checked;
        const checkedValue = e.target.value;
        checkedStatePrice[checkedValue].checkstatus = checked;

        var objrcv = props.searchdatarcv;
        var sp = [];

        checkedStatePrice.map((item, i) => {
            if (item.checkstatus) {
                sp.push(item.sprice + "-" + item.eprice);
            }
        });

        objrcv.price = sp;



        let queryparam = new URLSearchParams();
        if (objrcv.keyword != '') {
            queryparam.append('keyword', objrcv.keyword);
        }
        objrcv.price.map((item, i) => {
            console.log('item..', item);
            queryparam.append('price', item);

        });

        console.log('handleSearch form param..', queryparam.toString());
        //dispatch(loadAllHotel(props.searchdatarcv));
        dispatch(loadAllTourPackages(queryparam.toString()));


    };

    return (
        <aside className="hotel-filter">

            <div className="filter">
                <h2>Price</h2>
                <ul>

                    {checkedStatePrice.map((item, i) => {
                        return (
                            <li>
                                <span
                                    data-testid="checkboxFilter"
                                    className=" checkmarkOuter fadeEff"
                                >
                                    <label>₹ {item.sprice} - ₹ {item.eprice}</label>

                                    <input type="checkbox" className="htlchkbox" name="checkboxlist" id="checkboxlist"
                                        value={i}
                                        onChange={handleChange}
                                    />
                                </span>
                            </li>
                        )
                    })
                    }
                </ul>
            </div>

        </aside>

    )
}
