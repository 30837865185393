import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./contact.css"


function ContactUs() {



	const [input, setInput] = useState({
		name: "",
		email: "",
		mobile: "",
		message: ""

	})

	const { name, email, mobile, message } = input;

	const handleOnchange = (e) => {

		//alert(e.target.name)
		if (e.target.value.length == 11 && e.target.name == 'mobile') return false;
		setInput({ ...input, [e.target.name]: e.target.value });

	}



	let navigate = useNavigate();
	const handleOnsubmit = (e) => {

		e.preventDefault();
		const jsondata = { name, email, mobile, message };
		console.log(jsondata, "this is input")

		axios.post(`${process.env.REACT_APP_API_PATH}/api/v1/create-contact-enquiry`, jsondata)
			.then(res => {

				console.log(res);
				if (res != "") {

					alert("Request sent")
					setTimeout(() => {
						navigate("../");
					}, 2000);
				}
				else {
					alert("data not has send")
				}



			})

	}


	return (
		<>
			<div className="cardenquirywrap" >

				<div className="card cardenquiry" >
					<div className="card-body">
						<div className="divmain">
							<div className="upperrow" >
								<h1>contact us</h1>
							</div>
							<div className="upperrow">
								<h4 >We'd love to hear from you!</h4>
							</div>
							<div className="row input-container">

								<form onSubmit={handleOnsubmit} >
									<div class="col-xs-12">
										<div class="styled-input wide">
											<label>Name</label>
											<input type="text" required name='name' value={name} onChange={handleOnchange} />

										</div>
									</div>
									<div class="col-md-6 colmd6 col-sm-12">
										<div class="styled-input">
											<label>Email</label>
											<input type="email" required name='email' value={email} onChange={handleOnchange} />

										</div>
									</div>
									<div class="col-md-6 colmd6 col-sm-12">
										<div class="styled-input" >
											<label>Phone Number</label>
											<input type="number" name='mobile' value={mobile} onChange={handleOnchange} />

										</div>
									</div>
									<div class="col-xs-12">
										<div class="styled-input wide">
											<label>Message</label>
											<textarea name='message' value={message} onChange={handleOnchange}></textarea>

										</div>
									</div>
									<div class="col-xs-12">
										<button type='submit' className='button-3'>submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>



		</>
	)
}

export default ContactUs;