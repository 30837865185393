import React, { useState, useEffect } from "react";

import BannerTour from "../component/tour/BannerTour"
import ServiceTour from "../component/tour/ServiceTour"
import SideFilter from "../component/tour/SideFilter"
import TourData from "../component/tour/TourData"

export default function HolidayTour() {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = "Holiday Packages"
  }, [])

  let objsearchstart = {
    keyword: '',
    price: [],
  };

  const [searchobj, setSeacrhObject] = useState(objsearchstart);

  const setsearchkewords = (childData) => {

    setSeacrhObject(childData);
    console.log('..childData after', searchobj)
    // React.setState({ message: childData })
  }

  return (
    <>
      <BannerTour />
      <ServiceTour parentCallback={setsearchkewords} searchdatarcv={searchobj} />
      <div className="hotel-page section">
        <div className="container">
          <SideFilter parentCallback={setsearchkewords} searchdatarcv={searchobj} />
          <TourData parentCallback={setsearchkewords} searchdatarcv={searchobj} />
        </div>
      </div>
    </>
  )
}
