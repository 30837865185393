import React, { useState, useEffect } from "react";
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loadAllTourPackages } from "../../redux/action/TourPackagesAction"





export default function TourData(props) {


   const imgpath = ""
   const dispatch = useDispatch();

   useEffect(() => {

      var objrcv = props.searchdatarcv;



      let queryparam = new URLSearchParams();
      if (objrcv.keyword != '') {
         queryparam.append('keyword', objrcv.keyword);
      }
      objrcv.price.map((item, i) => {
         console.log('item..', item);
         queryparam.append('price', item);

      });

      console.log('handleSearch form param..', queryparam.toString());
      //dispatch(loadAllHotel(props.searchdatarcv));
      dispatch(loadAllTourPackages(queryparam.toString()));



   }, [])

   const Tours = useSelector((state) => state.TourPackages);

   const { result } = Tours;
   console.log(result, "tourrr")

   //console.log("tourrr")

   return (
      <div className="right-filter-content">

         <section className="best-offer hotel-booking">

            <ul className="filter-hotel-search">

               {result.map((data) => {
                  return (
                     <>


                        <li>
                           <div className="inner-content">
                              <Link to={'/tour-product/' + data.id}>
                                 <div className="offer-image">
                                    <img src={data.image} alt="" />
                                 </div>
                                 <div className="offer-content">
                                    <div className="top"><h2>{data.name}</h2><h4> <span className="crossprice"> ₹ {data.booking_price}  </span> <span>₹ {data.booking_price}  </span> </h4></div>
                                    <div className="bottom"><p>{data.no_of_days} Days/{data.no_of_nights} Nights </p><p>Starting Price</p></div>
                                 </div>
                              </Link>
                           </div>
                        </li>
                     </>
                  )
               })}





            </ul>

         </section>

      </div>
   )
}
