import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signinUser } from "../../redux/action/AuthAction";
import { toast, ToastContainer } from "react-toastify";

const SignIn = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const { email, password } = state;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(signinUser(email, password));
  };
  return (
    <>
      <ToastContainer />
      <div className="container pt-5">
        <div className="row">
          <div className="col-md-5 mx-auto bg-light shad">
            <h3 className="pt-4 text-center">Sign In</h3>
            <hr />
            <form onSubmit={handleSubmit}>
              <div class="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  required
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                />
              </div>
              <div class="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  required
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                />
              </div>
              <div class="forget pt-2">
                <Link to="/forgot">
                  <p className="link">Forgot Password ? </p>
                </Link>
              </div>
              <div className="form-group row mt-4 reg">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
              <div className="user pt-2 d-flex">
                <p>Not User ?</p>
                <Link to="/registration">
                  <p className="link">Create an Account </p>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { signinUser } from "../../redux/action/AuthAction";
// import { toast, ToastContainer } from "react-toastify";

// const SignIn = () => {
//   const dispatch = useDispatch();
//   const [state, setState] = useState({
//     number: "",

//   });

//   const {number } = state;

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setState({ ...state, [name]: value });
//     console.log(state,"av")
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     dispatch(signinUser(number));
//     console.log(number,"input")
//   };
//   return (
//     <>
//       <ToastContainer />
//       <div className="container pt-5">
//         <div className="row">
//           <div className="col-md-5 mx-auto bg-light shad">
//             <h3 className="pt-4 text-center">Sign In</h3>
//             <hr />
//             <form onSubmit={handleSubmit}>
//               <div class="form-group">
//                 <label htmlFor="exampleInputnumber">Mobile-Number</label>
//                 <input
//                   type="number"
//                   class="form-control"
//                   id="exampleInputnumber"
//                   aria-describedby="numberHelp"
//                   placeholder="Enter-number"
//                   required
//                   name="number"
//                   value={number}
//                   onChange={handleInputChange}
//                 />
//               </div>
//               {/* <div class="form-group">
//                 <label htmlFor="exampleInputPassword1">Password</label>
//                 <input
//                   type="password"
//                   class="form-control"
//                   id="exampleInputPassword1"
//                   placeholder="Password"
//                   required
//                   name="password"
//                   value={password}
//                   onChange={handleInputChange}
//                 />
//               </div> */}
//               <div class="forget pt-2">
//                 <Link to="/forgot">
//                   <p className="link">Forgot Password ? </p>
//                 </Link>
//               </div>
//               <div className="form-group row mt-4 reg">

//                 <button   type="submit" className="btn btn-primary">
//                   Submit
//                 </button>

//               </div>
//               <div className="user pt-2 d-flex">
//                 <p>Not User ?</p>
//                 <Link to="/registration">
//                   <p className="link">Create an Account </p>
//                 </Link>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default SignIn;
