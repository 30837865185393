import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadSingleHotel } from "../../../redux/action/HotelAction";
import { useParams } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";

export default function SingleNav() {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(loadSingleHotel(id));
  }, [dispatch, id]);

  const datarcv = useSelector((state) => state.Hotel.singledata);

  let price = {}
  console.log('..', datarcv.hoteldetails)
  if (datarcv.hoteldetails) {
    price = datarcv.hoteldetails[0];
  }
  return (
    <section className="section single-nav">
      <div className="container">
        <div className="navbar-single">
          <div className="navigation">
            <ul>
              <li>
                <Link to="">Overview</Link>
              </li>
              <li>
                <Link
                  to="room"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Room
                </Link>
              </li>
              <li>
                <Link
                  to="faclity"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  Facilities
                </Link>
              </li>

              {/* 
              <li>
                <Link to="">Reviews</Link>
              </li>
              <li>
                <Link to="">Location</Link>
              </li>
              */} 
            </ul>
          </div>
          <div className="starting-price">
            <span>from</span>

            <h3 className="hotel-price">

              <span>Rs.{price.starting_price}</span>

            </h3>

            <Link
              to="room"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="btn btn-primary deal-more"
            >
              {" "}
              View rooms
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
