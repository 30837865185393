import {
  GET_ALL_ROOM_FAIL,
  GET_ALL_ROOM_REQUEST,
  GET_ALL_ROOM_SUCCESS,
} from "../constant/RoomConstant";

import axios from "axios";

const loadAllRoom = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_ALL_ROOM_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/hotel/hotel-roomslist/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        // console.log("ffres", res);
        dispatch(loadAllRoomPre(res.data));
      })
      .catch((error) => {
        // console.log("error", error);

        dispatch({
          type: GET_ALL_ROOM_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllRoomPre = (data) => {
  return {
    type: GET_ALL_ROOM_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export { loadAllRoom };
