export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNIN_USER_REQUEST = "SIGNIN_USER_REQUEST";
export const SIGNIN_USER_ERROR = "SIGNIN_USER_ERROR";


export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SIGNOUT_USER = "SIGNOUT_USER";




export const SEND_USER_OTP_VERIFY = "SEND_USER_OTP_VERIFY";
export const SEND_USER_OTP = "SEND_USER_OTP";




export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const USER_PROFILE_UPDATE_REQUEST = "USER_PROFILE_UPDATE_REQUEST";
export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_FAIL = "USER_PROFILE_UPDATE_FAIL";

export const USER_PASSWORD_UPDATE_REQUEST = "USER_PASSWORD_UPDATE_REQUEST";
export const USER_PASSWORD_UPDATE_SUCCESS = "USER_PASSWORD_UPDATE_SUCCESS";
export const USER_PASSWORD_UPDATE_FAIL = "USER_PASSWORD_UPDATE_FAIL";
