import {
  GET_ALL_ROOM_FAIL,
  GET_ALL_ROOM_REQUEST,
  GET_ALL_ROOM_SUCCESS,
} from "../constant/RoomConstant";

const initialState = {
  loading: false,
  action: "ROOM",
  result: [],
  response: {},
  singledata: [],
  msg: "",
  error: "",
};

const RoomReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ROOM_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_ALL_ROOM_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_ALL_ROOM_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default RoomReducer;
