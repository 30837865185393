import {
  GET_ALL_TOURPACKAGES_FAIL,
  GET_ALL_TOURPACKAGES_REQUEST,
  GET_ALL_TOURPACKAGES_SUCCESS,
  GET_SINGLE_TOURPACKAGES_FAIL,
  GET_SINGLE_TOURPACKAGES_REQUEST,
  GET_SINGLE_TOURPACKAGES_SUCCESS,
  GET_TOURPACKAGES_IMAGE_REQUEST,
  GET_TOURPACKAGES_IMAGE_SUCCESS,
  GET_TOURPACKAGES_IMAGE_FAIL,
  GET_TOURPACKAGES_DAYSCHEDULE_REQUEST,
  GET_TOURPACKAGES_DAYSCHEDULE_SUCCESS,
  GET_TOURPACKAGES_DAYSCHEDULE_FAIL,
  GET_TOURPACKAGES_ENQUERY_REQUEST,
  GET_TOURPACKAGES_ENQUERY_SUCCESS,
  GET_TOURPACKAGES_ENQUERY_FAIL,
} from "../constant/TourPackagesConstant";

const initialState = {
  loading: false,
  action: "TOURPACKAGES",
  result: [],
  response: {},
  singledata: [],
  resultimages: [],
  days: [],
  submitenq: {},


  msg: "",
  error: "",
};

const TourPackagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TOURPACKAGES_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_ALL_TOURPACKAGES_SUCCESS:
      return {
        ...state,
        result: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_ALL_TOURPACKAGES_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case GET_SINGLE_TOURPACKAGES_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_SINGLE_TOURPACKAGES_SUCCESS:
      return {
        ...state,
        singledata: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_SINGLE_TOURPACKAGES_FAIL:
      return {
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case GET_TOURPACKAGES_IMAGE_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_TOURPACKAGES_IMAGE_SUCCESS:
      return {
        ...state,
        resultimages: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_TOURPACKAGES_IMAGE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case GET_TOURPACKAGES_DAYSCHEDULE_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_TOURPACKAGES_DAYSCHEDULE_SUCCESS:
      return {
        ...state,
        days: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_TOURPACKAGES_DAYSCHEDULE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    case GET_TOURPACKAGES_ENQUERY_REQUEST:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_TOURPACKAGES_ENQUERY_SUCCESS:
      return {
        ...state,
        submitenq: action.result.data,
        loading: action.payload,
        msg: action.msg,
      };
    case GET_TOURPACKAGES_ENQUERY_FAIL:
      return {
        ...state,
        error: action.error,
        loading: action.payload,
        msg: action.msg,
      };

    default:
      return state;
  }
};

export default TourPackagesReducer;
