import {
    GET_ALL_BANNER_REQUEST,
    GET_ALL_BANNER_SUCCESS,
    GET_ALL_BANNER_FAIL,
} from "../constant/WebsiteConstant"

import {
    GET_ALL_TESTIMONIAL_REQUEST,
    GET_ALL_TESTIMONIAL_SUCCESS,
    GET_ALL_TESTIMONIAL_FAIL,
} from "../constant/WebsiteConstant"



import axios from "axios";

const loadbannerlists = (id) => {
    return function (dispatch) {
        dispatch({
            type: GET_ALL_BANNER_REQUEST,
            payload: true,
        });

        let OPTION = {
            url: `${process.env.REACT_APP_API_PATH}/api/v1/web/banners`,
            method: "GET",
            headers: {
                "content-type": "application/json",
            },
        };

        axios(OPTION)
            .then((res) => {
                // console.log("ffres", res);
                dispatch(loadAllbannerPre(res.data));
            })
            .catch((error) => {
                // console.log("error", error);

                dispatch({
                    type: GET_ALL_BANNER_FAIL,
                    payload: false,
                    error: error,
                    msg: "Failed to load the information",
                });
            });
    };
};
export const loadAllbannerPre = (data) => {
    return {
        type: GET_ALL_BANNER_SUCCESS,
        result: data,
        payload: false,
        msg: "SUCCESS",
    };
};


const loadtestimoniallist = (id) => {
    return function (dispatch) {
        dispatch({
            type: GET_ALL_TESTIMONIAL_REQUEST,
            payload: true,
        });

        let OPTION = {
            url: `${process.env.REACT_APP_API_PATH}/api/v1/web/testimonials`,
            method: "GET",
            headers: {
                "content-type": "application/json",
            },
        };

        axios(OPTION)
            .then((res) => {
                // console.log("ffres", res);
                dispatch(loadtestimoniallistPre(res.data));
            })
            .catch((error) => {
                // console.log("error", error);

                dispatch({
                    type: GET_ALL_TESTIMONIAL_FAIL,
                    payload: false,
                    error: error,
                    msg: "Failed to load the information",
                });
            });
    };
};
export const loadtestimoniallistPre = (data) => {
    return {
        type: GET_ALL_TESTIMONIAL_SUCCESS,
        result: data,
        payload: false,
        msg: "SUCCESS",
    };
};

export { loadbannerlists, loadtestimoniallist };
