import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllHotelImage } from "../../../redux/action/HotelImageAction";

import { useParams } from "react-router-dom";

export default function HotelImage() {
  let dispatch = useDispatch();
  let { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(loadAllHotelImage(id));
    }
  }, [id, dispatch]);

  const hotelimage = useSelector((state) => state.HotelImage.result);

  return (
    <section className="hotelimage">
      <div className="container">
        <ul>
          {hotelimage.map((item, i) => {
            return (
              <li key={item.id}>
                {item.image && item.image == null ? (
                  ""
                ) : (
                  <img src={item.image} alt="" />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}
