import React, { useState, useEffect, shallowEqual } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { loadSingleTourPackages, TourPackageDauschedule, submittourenquiry } from "../../../redux/action/TourPackagesAction"

import { useParams } from "react-router-dom";

export default function MainData() {



  const dispatchTourData = useDispatch();
  const dispatchDays = useDispatch();
  const dispatchRequestSubmit = useDispatch();

  let { id } = useParams();

  const Tourpkg = useSelector((state) => state.TourPackages,);

  const { singledata } = Tourpkg;
  const { days } = Tourpkg;

  useEffect(() => {
    if (id) {
      dispatchTourData(loadSingleTourPackages(id));
      dispatchDays(TourPackageDauschedule(id));
    }

  }, [])



  let datadays = Array.from(days)

  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [enquiry, setEnquiry] = useState("");

  let navigate = useNavigate();


  const handleSubmit = (e) => {
    e.preventDefault();
    if (email != null && mobile != null) {
      dispatchRequestSubmit(
        submittourenquiry(
          name,
          email,
          mobile,
          enquiry,
          singledata[0]?.name,
          singledata[0]?.id,
        )
      );
      setTimeout(() => {
        alert("Your Request has been sent");
        setName('');
        setMobile('');

        setEmail('');
        setEnquiry('');

        dispatchDays(TourPackageDauschedule(id));

      }, 1000);


    } else {
      toast.error("Email And Contact Number is Required");
    }
  };

  return (
    <section className="section hotelsection holiday-single">
      <div className="container">
        <div className="hotel-decription">
          <div className="hotel-title">
            <h2 className="title">{singledata[0]?.name}</h2>
            <p className='packages'><strong>{singledata[0]?.no_of_days} Days/{singledata[0]?.no_of_nights} Nights at {singledata[0]?.city} </strong></p>
            <p className="hotel-address">{singledata[0]?.description} </p>

          </div>


          <div className="section facility">

            <div className="row">
              <div className="col-md-6">
                <h4>Inclusions</h4>
                <div className="facility" dangerouslySetInnerHTML={{ __html: singledata[0]?.inclusions }}>
                </div>
              </div>

              <div className="col-md-6">
                <h4>Exclusions</h4>
                <div className="facility" dangerouslySetInnerHTML={{ __html: singledata[0]?.exclusions }}>
                </div>
              </div>

            </div>


          </div>
          <div className="section facility">

            {datadays.map((data) => {
              return (
                <>
                  <div className="wrapday">
                    <div className="width-20">
                      <span className="day-box">
                        Day {data?.day}
                      </span>
                    </div>
                    <div className="width-80">
                      <div className="daycontent">
                        <p>Activity :- {data?.event_name} </p>

                        <div dangerouslySetInnerHTML={{ __html: data?.day_activity }}>

                        </div>
                        <div className="activitydescription" dangerouslySetInnerHTML={{ __html: data?.description }}>
                        </div>
                      </div>
                      <div className="daycontent">
                        <div className="daycontenthot">
                          <h4>Hotel</h4>

                          <span> {data?.hotel_check} </span>
                        </div>
                        <div className="daycontenthot">
                          <p>{data.hotel_name} </p>

                          <img src={data.hotel_image} />

                        </div>

                        <p>Meal :{data.meals} </p>


                      </div>

                    </div>
                  </div>
                </>
              )
            })}
          </div>

        </div>
        <div className="aside-hotel">
          <div className="hotel-contact">
            <p className="toursubmitheading">Provide Your Details to Know Best  Deals</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="col-md-12">
                  <label htmlFor="first_name">Package Name :- {singledata[0]?.name}</label>

                </div>
                <div className="col-md-12">
                  <label htmlFor="exampleInputEmail1">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Full Name"
                    required
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>


                <div className="col-md-12 ">
                  <label htmlFor="mobile">Phone Number</label>
                  <input
                    type="number"
                    required
                    class="form-control"

                    placeholder="Enter Your Mobile Number"
                    name="mobile"
                    value={mobile}
                    onChange={(e) => {
                      if (e.target.value.length == 11) return false;
                      setMobile(e.target.value)
                    }}
                  />
                </div>

                <div className="col-md-12 ">

                  <label htmlFor="mobile">Email </label>
                  <input
                    type="email"
                    class="form-control"
                    required
                    placeholder="Enter Your Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="col-md-12 ">

                  <label htmlFor="mobile">Comments </label>
                  <textarea
                    class="form-control"
                    placeholder="Comment"
                    name="enquiry"
                    value={enquiry}
                    onChange={(e) => setEnquiry(e.target.value)}
                  />
                </div>



                <div className="form-group row mt-4 reg">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>




            </form>
          </div>
        </div>
      </div>
    </section>

  )
}
