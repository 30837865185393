import {
    GET_ALL_BANNER_REQUEST,
    GET_ALL_BANNER_SUCCESS,
    GET_ALL_BANNER_FAIL,
} from "../constant/WebsiteConstant"

import {
    GET_ALL_TESTIMONIAL_REQUEST,
    GET_ALL_TESTIMONIAL_SUCCESS,
    GET_ALL_TESTIMONIAL_FAIL,
} from "../constant/WebsiteConstant"


const initialState = {
    loading: false,
    action: "ADDTIONALFACILITY",
    testimonials: [],
    banners: [],

    response: {},
    singledata: [],
    msg: "",
    error: "",
};

const WebsiteReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_BANNER_REQUEST:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_BANNER_SUCCESS:
            return {
                ...state,
                banners: action.result.data,
                loading: action.payload,
                msg: action.msg,
            };
        case GET_ALL_BANNER_FAIL:
            return {
                ...state,
                error: action.error,
                loading: action.payload,
                msg: action.msg,
            };

        case GET_ALL_TESTIMONIAL_REQUEST:
            return {
                ...state,
                loading: action.payload,
            };
        case GET_ALL_TESTIMONIAL_SUCCESS:
            return {
                ...state,
                testimonials: action.result.data,
                loading: action.payload,
                msg: action.msg,
            };
        case GET_ALL_TESTIMONIAL_FAIL:
            return {
                ...state,
                error: action.error,
                loading: action.payload,
                msg: action.msg,
            };

        default:
            return state;
    }
};

export default WebsiteReducer;
