import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { loadbannerlists } from "../../redux/action/WebsiteAction"


const Banner = () => {



  const imgpath = ""
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(loadbannerlists());


  }, [])

  const Tours = useSelector((state) => state.Website);

  const { banners } = Tours;
  console.log(banners, "Website")

  /*
  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2000,

  }; */
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,




    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]


  };

  return (
    <>
      <div className="slick-slide-top-banner">
        <Slider {...settings}>


          {banners.map((data) => {
            return (
              <>

                <li>
                  <div class="poster-home">



                    <img src={data.imageurl} />
                    <div className="centered-slider-div">

                      <h3> {data.title}</h3>
                      <p>
                        {data.banner_text}
                      </p>
                    </div>


                  </div>
                </li>
              </>
            )
          })}
        </Slider>
      </div>
      {/*

       <Slider {...settings}>
          {images.map((item) => (
            <div key={item.id}>

              <img src={item.src} alt={item.alt} />

              <div className="centered-slider-div">

                <h3> {item.alt}</h3>
                <p>
                  {item.alt}
                </p>
              </div>

            </div>
          ))}
        </Slider>
        
      <div className="container-fluid">
        <div className="">
         

      
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 poster-home" >


        
            <h3> Hotel, Travel &amp; Tourism</h3>
            <p>
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              <br />
              used in laying out print, graphic or web designs.
            </p>
            <Link to="/registration">
              <button type="button" className="btn btn-warning">
                BOOK NOW
              </button>
            </Link>
 
          </div>  
        </div>
      </div> */}
    </>
  );
};

export default Banner;
