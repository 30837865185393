import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import Loader from "../share/Loader";
import Message from "../share/Message";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  searchCityList,
  searchHotelList,
} from "../../redux/action/HotelListAction";
import HotelSearch from "./HotelSearch";
import { loadAllHotel } from "../../redux/action/HotelAction";

const ServiceFormHotel = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [keyword, setKeyword] = useState("");
  const [hide, setHide] = useState(true);
  const [hide1, setHide1] = useState(true);
  const [city_name, setCity_name] = useState("784");
  const [city_id, setCity_id] = useState(0);

  const [check_in, setCheck_in] = useState("");
  const [check_out, setCheck_out] = useState("");
  const [no_rooms, setNo_rooms] = useState("");
  const [no_adults, setNo_adults] = useState("");
  const [no_children, setNo_children] = useState("");

  const city = useSelector((state) => state.HotelList);
  const { loading, error, cityList, result } = city;

  useEffect(() => {
    //dispatch(searchCityList('delhi'));
    console.log('cityList', cityList);
  }, []);

  const handleSubmit = (e) => {




    e.preventDefault();
    if (!keyword) {
      setHide(true);
    } else {
      dispatch(searchCityList(keyword));
      setHide(false);
    }

  };

  const clickli = (e) => {

    setCity_id(e.currentTarget.value)
    setKeyword(e.currentTarget.dataset.city)
    setHide(true);



  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (city_name !== "" && city_name !== null) {

      var objrcv = props.searchdatarcv;
      objrcv.city_id = city_id;


      let queryparam = new URLSearchParams();
      if (objrcv.city_id != '') {
        queryparam.append('city_id', objrcv.city_id);
      }
      objrcv.price.map((item, i) => {
        console.log('item..', item);
        queryparam.append('price', item);

      });
      if (objrcv.star != '') {
        queryparam.append('star', objrcv.star);
      }
      if (objrcv.facility != '') {
        queryparam.append('facility', objrcv.facility);
      }
      console.log('handleSearch form param..', queryparam.toString());
      dispatch(loadAllHotel(queryparam.toString()));
      //dispatch(loadAllHotel(props.searchdatarcv));
//No property found for your search criteria. Please reset filters
    }
  };

  return (
    <>
      <section className="hotel-booking-form">
        <div className="container">
          <form>
            <div className="main-box">
              <div className="form-field">
                <label>City List</label>

                <input
                  type="search"
                  placeholder="Search..."
                  required
                  name="keyword"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  onKeyUp={handleSubmit}
                />

                {loading ? (
                  <Loader />
                ) : error ? (
                  <Message variant="danger">{error}</Message>
                ) : (
                  <>
                    {hide !== true ? (
                      <>
                        {cityList ? (
                          <>
                            <ul className="search-list">
                              {cityList &&
                                cityList.map((item, i) => (
                                  <li key={i} value={item.id} onClick={clickli} data-city={item.city_name}>
                                    <p>
                                      <span className="name-item">
                                        {item.city_name}
                                      </span>
                                      -
                                      <span className="name-item">
                                        {item.state_name}
                                      </span>
                                      -
                                      <span className="name-item">
                                        {item.country_name}
                                      </span>
                                    </p>
                                  </li >
                                ))}
                            </ul>
                          </>
                        ) : (
                          <>
                            <ul className="search-list">
                              <li>
                                <span>No Product Found</span>
                              </li>
                            </ul>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>

              <div className="form-field form-medium">
                <label>Check in</label>
                <input
                  required
                  type="date"
                  name="check_in"
                  value={check_in}
                  onChange={(e) => setCheck_in(e.target.value)}
                />
              </div>

              <div className="form-field form-medium">
                <label>Check Out</label>
                <input
                  type="date"
                  name="check_out"
                  value={check_out}
                  onChange={(e) => setCheck_out(e.target.value)}
                />
              </div>

              <div className="form-field form-small">
                <label>Rooms</label>
                <select
                  name="no_rooms"
                  value={no_rooms}
                  onChange={(e) => setNo_rooms(e.target.value)}
                >
                  <option>0</option>
                  <option defaultValue value={1}>
                    1
                  </option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                </select>
              </div>

              <div className="form-field form-small">
                <label>Adults</label>
                <select
                  name="no_adults"
                  value={no_adults}
                  onChange={(e) => setNo_adults(e.target.value)}
                >
                  <option>0</option>
                  <option defaultValue value={1}>
                    1
                  </option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                </select>
              </div>

              <div className="form-field form-small">
                <label>Children</label>
                <select
                  name="no_children"
                  value={no_children}
                  onChange={(e) => setNo_children(e.target.value)}
                >
                  <option defaultValue value={1}>
                    1
                  </option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                </select>
              </div>
              <div className="form-field-btn">


                <button
                  className="btn btn-warning btn-booking btnholetsearch"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </div>

          </form>
        </div>
      </section>
      <section>
        {hide1 === false ? (
          <>
          </>
        ) : (
          <></>
        )}
      </section>
    </>
  );
}

export default ServiceFormHotel;
