import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import Loader from "../share/Loader";
import Message from "../share/Message";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { loadAllTourPackages } from "../../redux/action/TourPackagesAction"



export default function ServiceTour(props) {

  const [searchtxtt, setSearchtxtt] = useState("");
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    e.preventDefault();

    console.log('searchtxtt', searchtxtt)


    var objrcv = props.searchdatarcv;
    objrcv.keyword = searchtxtt;


    let queryparam = new URLSearchParams();
    if (objrcv.keyword != '') {
      queryparam.append('keyword', objrcv.keyword);
    }
    objrcv.price.map((item, i) => {
      console.log('item..', item);
      queryparam.append('price', item);

    });

    console.log('handleSearch form param..', queryparam.toString());
    dispatch(loadAllTourPackages(queryparam.toString()));
    //dispatch(loadAllHotel(props.searchdatarcv));
    //No property found for your search criteria. Please reset filters



  };

  return (


    <section className="hotel-booking-form">
      <div className="container">
        <form>
          <div className="holiday-search">
            <div className="form-field">

              <input
                type="search"
                placeholder="Where you want to go?"
                name="searchtxtt"
                value={searchtxtt}
                onChange={(e) => setSearchtxtt(e.target.value)}
              />
            </div>


            <div className="form-field-btn">
              <button
                className="btn btn-warning btn-booking btnholetsearch"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>  </div>
        </form>
      </div>
    </section>
  )
}
