import React from "react";

const NewPassword = () => {
  const handleSubmit = () => {};
  return (
    <div>
      <div className="container pt-5">
        <div className="row ">
          <div className="col-md-12 col-lg-4 mx-auto bg-light shad">
            <h3 className="pt-4 text-center">Reset Password</h3>
            <hr />
            <form onSubmit={handleSubmit}>
              <div class="form-group">
                <label htmlFor="exampleInputPassword1">New Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  name="password"
                  required
                />
              </div>
              <div class="form-group">
                <label htmlFor="exampleInputPassword1">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  class="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  name="password"
                  required
                />
              </div>

              <div className="form-group row p-3 reg">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
