import React from 'react'

function Service3() {
    return (
        <>
<div className="container-fluid">
    
<div class="row g-3 pt-3 pt-sm-0 pt-lg-5 pt-md-5 d-flex justify-content-center service">
                <div class="col-md-3 " style={{border:"2px solid red"}}>
                    <select class="form-select" aria-label="Default select example">
                        <option>Select Service</option>

                    </select>
                </div>

                <div class="col-md-3 " style={{border:"2px solid red"}}>
                    <input
                        type="date"
                        class="form-control"
                        id="exampleFormControlInput1"
                        placeholder="name@example.com"
                    ></input>
                </div>



                <div class="col-md-3 " style={{border:"2px solid red"}}>
                    <input
                        type="date"
                        class="form-control"
                        id="exampleFormControlInput1"
                        placeholder="name@example.com"
                    ></input>
                </div>

                <div class="col-md-3 " style={{border:"2px solid red"}}>
                    <input
                        type="text"
                        class="form-control"
                        id="exampleFormControlInput1"
                        placeholder="No. of person"
                    ></input>
                </div>

                <div class="col-lg-2 col-md-6">
                    <button type="button" className="btn btn-warning">
                        Search
                    </button>
                </div>
            </div>
</div>
        </>
    )
}

export default Service3;
