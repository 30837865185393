// constant files

export const ADD_HOTELIMAGE_REQUEST = "ADD_HOTELIMAGE_REQUEST";
export const ADD_HOTELIMAGE_SUCCESS = "ADD_HOTELIMAGE_SUCCESS";
export const ADD_HOTELIMAGE_FAIL = "ADD_HOTELIMAGE_FAIL";

export const UPDATE_HOTELIMAGE_REQUEST = "UPDATE_HOTELIMAGE_REQUEST";
export const UPDATE_HOTELIMAGE_SUCCESS = "UPDATE_HOTELIMAGE_SUCCESS";
export const UPDATE_HOTELIMAGE_FAIL = "UPDATE_HOTELIMAGE_FAIL";

export const DELETE_HOTELIMAGE_REQUEST = "DELETE_HOTELIMAGE_REQUEST";
export const DELETE_HOTELIMAGE_SUCCESS = "DELETE_HOTELIMAGE_SUCCESS";
export const DELETE_HOTELIMAGE_FAIL = "DELETE_HOTELIMAGE_FAIL";

export const GET_ALL_HOTELIMAGE_REQUEST = "GET_ALL_HOTELIMAGE_REQUEST";
export const GET_ALL_HOTELIMAGE_SUCCESS = "GET_ALL_HOTELIMAGE_SUCCESS";
export const GET_ALL_HOTELIMAGE_FAIL = "GET_ALL_HOTELIMAGE_FAIL";

export const GET_SINGLE_HOTELIMAGE_REQUEST = "GET_SINGLE_HOTELIMAGE_REQUEST";
export const GET_SINGLE_HOTELIMAGE_SUCCESS = "GET_SINGLE_HOTELIMAGE_SUCCESS";
export const GET_SINGLE_HOTELIMAGE_FAIL = "GET_SINGLE_HOTELIMAGE_FAIL";

export const HOTELIMAGE_CHANGE_STATUS_REQUEST =
  "HOTELIMAGE_CHANGE_STATUS_REQUEST";
export const HOTELIMAGE_CHANGE_STATUS_SUCCESS =
  "HOTELIMAGE_CHANGE_STATUS_SUCCESS";
export const HOTELIMAGE_CHANGE_STATUS_FAIL = "HOTELIMAGE_CHANGE_STATUS_FAIL";
