import React from 'react'
import banner from "../../image/taxi-banner.jpg"

export default function TaxiMainBanner() {
  return (
 <section className="inner-banner">
<img src={banner} alt=""/>
    <div className="container">
    <h1></h1>
    </div>


</section>

)
}
