import {
  ADD_HOTELIMAGE_FAIL,
  ADD_HOTELIMAGE_REQUEST,
  ADD_HOTELIMAGE_SUCCESS,
  DELETE_HOTELIMAGE_FAIL,
  DELETE_HOTELIMAGE_REQUEST,
  DELETE_HOTELIMAGE_SUCCESS,
  GET_ALL_HOTELIMAGE_FAIL,
  GET_ALL_HOTELIMAGE_REQUEST,
  GET_ALL_HOTELIMAGE_SUCCESS,
  GET_SINGLE_HOTELIMAGE_FAIL,
  GET_SINGLE_HOTELIMAGE_REQUEST,
  GET_SINGLE_HOTELIMAGE_SUCCESS,
  HOTELIMAGE_CHANGE_STATUS_FAIL,
  HOTELIMAGE_CHANGE_STATUS_REQUEST,
  HOTELIMAGE_CHANGE_STATUS_SUCCESS,
  UPDATE_HOTELIMAGE_FAIL,
  UPDATE_HOTELIMAGE_REQUEST,
  UPDATE_HOTELIMAGE_SUCCESS,
} from "../constant/HotelImageConstant";

import axios from "axios";
import { toast } from "react-toastify";

const loadAllHotelImage = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_ALL_HOTELIMAGE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/hotel/hotel-images/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        console.log("res", res);
        dispatch(loadAllHotelImagePre(res.data));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch({
          type: GET_ALL_HOTELIMAGE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllHotelImagePre = (data) => {
  return {
    type: GET_ALL_HOTELIMAGE_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadSingleHotelImage = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_SINGLE_HOTELIMAGE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin/hotel-image-hotel-id/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(loadSingleHotelImagePre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: GET_SINGLE_HOTELIMAGE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadSingleHotelImagePre = (data) => {
  return {
    type: GET_SINGLE_HOTELIMAGE_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const createHotelImage = (data) => {
  return function (dispatch) {
    dispatch({
      type: ADD_HOTELIMAGE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-Hotel/`,
      method: "POST",
      data: data,

      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(createHotelImagePre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: ADD_HOTELIMAGE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger("Failed To Create The Hotel!");
      });
  };
};
export const createHotelImagePre = (data) => {
  toast.success("Hotel Created Successfully!");
  return {
    type: ADD_HOTELIMAGE_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const updateHotelImage = (data) => {
  return function (dispatch) {
    dispatch({
      type: UPDATE_HOTELIMAGE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-Hotel/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(updateHotelImagePre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_HOTELIMAGE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger("Failed To Update The Hotel!");
      });
  };
};
export const updateHotelImagePre = (data) => {
  toast.success("Hotel Updated Successfully!");

  return {
    type: UPDATE_HOTELIMAGE_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const deleteHotelImage = (data) => {
  return function (dispatch) {
    dispatch({
      type: DELETE_HOTELIMAGE_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-Hotel-delete/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(deleteHotelImagePre(res.data));
        // dispatch(loadAllHotel());
      })
      .catch((error) => {
        dispatch({
          type: DELETE_HOTELIMAGE_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const deleteHotelImagePre = (data) => {
  toast.error("Hotel Deleted Successfully!");
  return {
    type: DELETE_HOTELIMAGE_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const HotelImageChangeStatus = (data) => {
  return function (dispatch) {
    dispatch({
      type: HOTELIMAGE_CHANGE_STATUS_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-Hotel-visible/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(HotelImageChangeStatusPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: HOTELIMAGE_CHANGE_STATUS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const HotelImageChangeStatusPre = (data) => {
  toast.success("Hotel Image changeStatus Successfully!");
  return {
    type: HOTELIMAGE_CHANGE_STATUS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export {
  loadAllHotelImage,
  loadSingleHotelImage,
  createHotelImage,
  updateHotelImage,
  deleteHotelImage,
  HotelImageChangeStatus,
};
