import React, { useEffect } from 'react'
import Background from "../image/hotel-banner.jpg"

export default function Aboutus() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "About |  flutter tourism"
    }, [])
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 poster" style={{ backgroundImage: `url(${Background})` }}>
                        <h3>  Welcome to Flutter!
                            <br></br>
                            Your choice of destination and route is here!

                        </h3>
                    </div>
                </div>
            </div>





            <div className='staticpagewrapper'>
                <p className='heading'>
                    Your choice of destination and route is here!
                </p>
                <p>
                    The premium player in online hotel bookings in India, Flutter offers fantastic offers, some of the lowest hotel bookings, exclusive discounts, and a seamless online booking experience. Cabs, flights, tour packages, hotel, and holiday bookings through the desktop or mobile site is a wonderful customer friendly experience, and with just a few clicks, you can complete your booking on our website. With features like Instant Discounts, Fare Calendar, and many more, the overall booking experience with Flutter continually adds value to its product. It continues to offer the best to its customers. Our focused value differentiator is the most trusted user experience, be it in terms of quickest search and booking, fastest payments, settlement or refund processes.
                </p>


                <p className='heading'> OUR PRODUCTS

                </p>
                <p> <span className="dot"></span> Domestic Hotels.
                    <br></br> <span className="dot"></span> International Hotels
                    <br></br> <span className="dot"></span> Domestic Flights Tickets
                    <br></br> <span className="dot"></span> International Flights Tickets
                    <br></br> <span className="dot"> </span>  Cab Bookings

                    <br></br> <span className="dot"></span> Holiday Tour Packages
                    <br></br> <span className="dot"></span> Sightseeing
                    <br></br> <span className="dot"></span> Other Fun Activities
                </p>

                <p className='heading'>Why Flutter for Hotel Bookings </p>

                <p>
                    Flutter, being one of the premium hotel booking sites in the country, offers several discounts on budget hotels.
                    If you are searching for the cheapest hotels with amazing deals, you can drag down the Price option from Rs.0 to Rs.500 or
                    from Rs.0 to Rs.1000. Decide from the various amenities you would require during your stay, including room service,
                    access to Wi-Fi, and in-house restaurants. The list will initially show you the cheapest one on top. You can also
                    sort by prices from Low to High, scroll down the list and find your preferred ones with great discounts and deals.
                    This includes letting you shortlist your hotel premise the locality, for example, Jaipur City Centre, North Jaipur,
                    South Jaipur, among others. Also, an amenity-wise search is another way of landing a hotel of your choice. For example,
                    you are traveling with children, and you select the option 'suitable for children' to get a progressively more relevant result.
                </p>

                <p className='heading'>  You can book hotels in India and International hotels sitting at home in simple clicks  </p>

                <p>  <span className="dot"></span> You can quickly check reviews, ratings, images, and amenities of all the hotels in one place.
                    Also, compare hotels to suit your preferences for hotel booking.
                    <br></br> <span className="dot"></span> You can avail great discounts and offers on hotel booking through Flutter.
                    <br></br> <span className="dot"></span> Avoiding the last-minute hotel search in an unknown city with high expenses,
                    you can book a great stay for yourself at reasonable prices online.
                    <br></br> <span className="dot"></span> You can customize your hotel to stay with a lot range of room plans available on our site.
                    <br></br> <span className="dot"></span> You can read all the hotel policies online and also experience a hassle-free stay at the preferred hotels.

                </p>









                <p className='heading'>Why Flutter for Cabs Bookings </p>
                <p>
                    The premium player in online cab booking services in India, Flutter offers great offers on cab fares, exclusive discounts, and consistent online booking experience. Cabs bookings on website through the desktop or mobile is a wonderfully customer friendly experience. With just a few clicks you can complete your booking.

                </p>
                <p>
                    Book your cabs with India's premium online booking company. While booking cabs with Flutter, you can expect the definitive online booking experience. With premium customer service, top rated drivers and cabs, 24/7 devoted helpline for support, and over 5 million delighted customers, Flutter takes great pride in enabling customer satisfaction.

                </p>
            </div>

        </>
    )
}
