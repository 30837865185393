import React, {useEffect} from 'react'
import TaxiMainBanner from "../component/taxi/TaxiMainBanner"
import TaxiService from "../component/taxi/TaxiService"
import TaxiSatisfaction from "../component/taxi/TaxiSatisfaction"
import TaxiContent from "../component/taxi/TaxiContent"
import { useDispatch, useSelector } from "react-redux";
import {loadAllAddtionalFacility_Taxi} from "../redux/action/TaxiBookingAction"

export default function TaxiBooking() {

  const dispatch = useDispatch();


 


    useEffect(() => {
        window.scrollTo(0, 0)
        document.title="Taxi Booking"
      }, [])


  return (
    <>
    <TaxiMainBanner/>
    <TaxiService/>

    

    <TaxiContent/>
    <TaxiSatisfaction/>
    </>
    )
}
