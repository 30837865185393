import React, { useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { useDispatch, useSelector } from "react-redux";
import { loadAllHotel } from "../../redux/action/HotelAction";
import { Link, useParams } from "react-router-dom";
import Loader from "../share/Loader";
import Message from "../share/Message";

const HotelBookings = () => {

  const dispatch = useDispatch();
  // console.log("dispacth data");
  // console.log(dispatch);

  useEffect(() => {
    //alert(id)
    if (id) {
      dispatch(loadAllHotel(id));
    }
    else {
      dispatch(loadAllHotel(id));
    }

  }, []);

  const hotel = useSelector((state) =>
    state.Hotel.result

  );

  const { loading, error } = hotel;
  //  console.log("hotel", hotel);
  // console.log("error")
  // console.log(error);
  let { id } = useParams();

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <section class="section best-offer hotel-booking">
      <div class="container">
        <h2 class="title left">
          Hotel <span>Bookings</span>
        </h2>
        <ul class="">
          <Slider {...settings}>
            {hotel.map((item, i) => {
              return (
                <li key={item.id}>
                  <Link to={`/hotel-detail/${item.id}`}>
                    <div class="inner-content">
                      <div class="offer-image">
                        {item.image && item.image == null ? (
                          ""
                        ) : (
                          <img src={item.image} />
                        )}
                      </div>
                      <div class="offer-content">
                        <div class="top">
                          <h2>{item.name}</h2>
                          <h4>Starting Price  ₹ {item.starting_price}</h4>
                        </div>
                        <div class="bottom">
                          <span >Review Score</span>
                          <span className="hotelrating">{item.rating}</span>

                          {item.goi_location &&
                            item.goi_location !== null ? (
                            <p>
                              <i class="fa fa-map-marker"></i>{" "}
                              {item.goi_location}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              );
            })}

          </Slider>
        </ul>
      </div>
    </section>
  );
};

export default HotelBookings;
