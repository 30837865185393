import {
  ADD_HOTEL_FAIL,
  ADD_HOTEL_REQUEST,
  ADD_HOTEL_SUCCESS,
  DELETE_HOTEL_FAIL,
  DELETE_HOTEL_REQUEST,
  DELETE_HOTEL_SUCCESS,
  GET_ALL_HOTEL_FAIL,
  GET_ALL_HOTEL_REQUEST,
  GET_ALL_HOTEL_SUCCESS,
  GET_SINGLE_HOTEL_FAIL,
  GET_SINGLE_HOTEL_REQUEST,
  GET_SINGLE_HOTEL_SUCCESS,
  HOTEL_CHANGE_STATUS_FAIL,
  HOTEL_CHANGE_STATUS_REQUEST,
  HOTEL_CHANGE_STATUS_SUCCESS,
  UPDATE_HOTEL_FAIL,
  UPDATE_HOTEL_REQUEST,
  UPDATE_HOTEL_SUCCESS,

  GET_HOTEL_CATEGORY_REQUEST,
  GET_HOTEL_CATEGORY_SUCCESS,
  GET_HOTEL_CATEGORY_FAIL,
  GET_HOTEL_FACILITY_REQUEST,
  GET_HOTEL_FACILITY_SUCCESS,
  GET_HOTEL_FACILITY_FAIL,
} from "../constant/HotelConstant";

import axios from "axios";
import { toast } from "react-toastify";

const loadAllHotel = (datarcv) => {
  return function (dispatch) {
    dispatch({
      type: GET_ALL_HOTEL_REQUEST,
      payload: true,
    });

    // var qrparam = "city_id=" + datarcv.city_id + "&sprice=" + datarcv.sprice + "&eprice=" + datarcv.eprice + "&star=" + datarcv.star + "&facility=" + datarcv.facility;

    let OPTION = {
      url: `http://103.145.51.109:3004/api/v1/hotel/hotel-list?` + datarcv,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };




    axios(OPTION)
      .then((res) => {
        // console.log("res", res);
        dispatch(loadAllHotelPre(res.data));
      })
      .catch((error) => {
        // console.log("error", error);

        dispatch({
          type: GET_ALL_HOTEL_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllHotelPre = (data) => {
  return {
    type: GET_ALL_HOTEL_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};


const loadAllHotelCategory = () => {
  return function (dispatch) {
    dispatch({
      type: GET_HOTEL_CATEGORY_REQUEST,
      payload: true,
    });

    // var qrparam = "city_id=" + datarcv.city_id + "&sprice=" + datarcv.sprice + "&eprice=" + datarcv.eprice + "&star=" + datarcv.star + "&facility=" + datarcv.facility;

    let OPTION = {
      url: 'http://103.145.51.109:3004/api/v1/hotel/hotel-category',
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };




    axios(OPTION)
      .then((res) => {
        // console.log("res", res);
        dispatch(loadAllHotelcatePre(res.data));
      })
      .catch((error) => {
        // console.log("error", error);

        dispatch({
          type: GET_HOTEL_CATEGORY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllHotelcatePre = (data) => {
  return {
    type: GET_HOTEL_CATEGORY_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const loadAllHotelFacility = () => {
  return function (dispatch) {
    dispatch({
      type: GET_HOTEL_FACILITY_REQUEST,
      payload: true,
    });

    // var qrparam = "city_id=" + datarcv.city_id + "&sprice=" + datarcv.sprice + "&eprice=" + datarcv.eprice + "&star=" + datarcv.star + "&facility=" + datarcv.facility;

    let OPTION = {
      url: 'http://103.145.51.109:3004/api/v1/hotel/hotel-facility',
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };




    axios(OPTION)
      .then((res) => {
        // console.log("res", res);
        dispatch(loadAllHotelfacPre(res.data));
      })
      .catch((error) => {
        // console.log("error", error);

        dispatch({
          type: GET_HOTEL_FACILITY_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadAllHotelfacPre = (data) => {
  return {
    type: GET_HOTEL_FACILITY_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};


const loadSingleHotel = (id) => {
  return function (dispatch) {
    dispatch({
      type: GET_SINGLE_HOTEL_REQUEST,
      payload: true,
    });




    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/hotel/hotel-detail/${id}/`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };




    axios(OPTION)
      .then((res) => {
        dispatch(loadSingleHotelPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: GET_SINGLE_HOTEL_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const loadSingleHotelPre = (data) => {
  return {
    type: GET_SINGLE_HOTEL_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};





const createHotel = (data) => {
  return function (dispatch) {
    dispatch({
      type: ADD_HOTEL_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin/hotel/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(createHotelPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: ADD_HOTEL_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger("Failed To Create The Hotel!");
      });
  };
};
export const createHotelPre = (data) => {
  toast.success("Hotel Created Successfully!");
  return {
    type: ADD_HOTEL_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const updateHotel = (data) => {
  return function (dispatch) {
    dispatch({
      type: UPDATE_HOTEL_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-Hotel/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(updateHotelPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_HOTEL_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
        toast.danger("Failed To Update The Hotel!");
      });
  };
};
export const updateHotelPre = (data) => {
  toast.success("Hotel Updated Successfully!");

  return {
    type: UPDATE_HOTEL_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const deleteHotel = (data) => {
  return function (dispatch) {
    dispatch({
      type: DELETE_HOTEL_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-Hotel-delete/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(deleteHotelPre(res.data));
        // dispatch(loadAllHotel());
      })
      .catch((error) => {
        dispatch({
          type: DELETE_HOTEL_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const deleteHotelPre = (data) => {
  toast.error("Hotel Deleted Successfully!");
  return {
    type: DELETE_HOTEL_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

const HotelChangeStatus = (data) => {
  return function (dispatch) {
    dispatch({
      type: HOTEL_CHANGE_STATUS_REQUEST,
      payload: true,
    });

    let OPTION = {
      url: `${process.env.REACT_APP_API_PATH}/api/v1/admin-Hotel-visible/`,
      method: "POST",
      data: data,
      headers: {
        "content-type": "application/json",
      },
    };

    axios(OPTION)
      .then((res) => {
        dispatch(HotelChangeStatusPre(res.data));
      })
      .catch((error) => {
        dispatch({
          type: HOTEL_CHANGE_STATUS_FAIL,
          payload: false,
          error: error,
          msg: "Failed to load the information",
        });
      });
  };
};
export const HotelChangeStatusPre = (data) => {
  toast.success("Hotel changeStatus Successfully!");
  return {
    type: HOTEL_CHANGE_STATUS_SUCCESS,
    result: data,
    payload: false,
    msg: "SUCCESS",
  };
};

export {
  loadAllHotel,
  loadSingleHotel,
  createHotel,
  updateHotel,
  deleteHotel,
  HotelChangeStatus,
  loadAllHotelCategory,
  loadAllHotelFacility
};
