import React, { useEffect } from "react";

import mohan from "../../image/mohan.png";
import judan from "../../image/judan.png";
import ashutosh from "../../image/ashutosh.png";
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";


import quotes from '../../image/quotes.png';

import { loadtestimoniallist } from "../../redux/action/WebsiteAction"



export default function Testimonial() {

  const imgpath = ""
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(loadtestimoniallist());


  }, [])

  const Tours = useSelector((state) => state.Website);

  const { testimonials } = Tours;
  console.log(testimonials, "Website")

  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='section testimonial'>
      <div className='container'>
        <h2 className='title'>What our <span>customer says</span></h2>
        <ul className=''>
          <Slider {...settings}>
            {testimonials.map((testimonial) => {


              return (
                <li key={testimonial.id}>
                  <div className="testimonial_inner">
                    <img src={quotes} alt="" />
                    <div className="testinomial_text">
                      <p>{testimonial.comment}</p></div>
                    <div className='testinimial_image'>
                      <img src={testimonial.imageurl} alt={testimonial.name} />
                      <div className='testi_name'>
                        <h4>{testimonial.name}</h4>
                        <span>({testimonial.user_from})</span>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })}
          </Slider>
        </ul>
      </div>
    </div>

  )

}




