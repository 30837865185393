import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { createBookingRoom } from "../../../redux/action/BookingRoomAction";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BookNow = ({ r_id, id, setFunction }) => {
  console.log("room_id", r_id);
  console.log("id", id);
  const dispatch = useDispatch();
  const [state, setstate] = useState({
    fullname: "",
    email: "",
    phone: "",
    arrdate: "",
    dipdate: "",
    guest: "",
  });
  const {
    fullname,
    email,
    phone,
    arrdate,
    dipdate,
    guest,
  } = state;

  const handleInputChange = (e) => {


    const { name, value } = e.target;

    console.log('...', name);
    if (name == "phone") {
      setstate({ ...state, [name]: value.slice(0, 10) });

    }
    if (name == "guest") {
      setstate({ ...state, [name]: value.slice(0, 2) });

    }
    else {
      setstate({ ...state, [name]: value });

    }
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    if (!fullname) {
      alert("Plz Fill ALl Input Filed");
    }

    let newData = {
      hotel_id: id,
      room_id: r_id,
      fullname,
      email,
      phone,
      arrdate,
      dipdate,
      guest,
    };
    dispatch(createBookingRoom(newData));
    setFunction();
  };
  const gobackclick = (e) => {
    setFunction();

  }

  return (
    <>
      <ToastContainer />
      <div className="container pt-5">
        <div className="row">


          <div className="col-lg-8 col-md-12 bg-light mx-auto shad">
            <a href="# " onClick={gobackclick}>Go Back or View All Rooms</a>
            <h3 className="pt-4">Let us know who you are</h3>
            <form onSubmit={handlesubmit}>

              <div className="form-group d-flex">
                <div className="col-md-6">
                  <label htmlFor="firstname">Full Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Full Name"
                    required
                    name="fullname"
                    value={fullname}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 left">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="number"
                    class="form-control"
                    id="exampleInputPassword1"
                    // placeholder="Enter Your Mobile Number"
                    value={phone}
                    name="phone"
                    maxLength={10}
                    onChange={handleInputChange}
                  />
                </div>

              </div>
              <div className="form-group mt-3 d-flex">
                <div className="col-lg-6">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    value={email}
                    name="email"
                    onChange={handleInputChange}
                  />
                </div>
                <div class="col-md-6 left">
                  <label htmlFor="inputState">Number Of Guests </label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Number Of Guests"
                    value={guest}
                    name="guest"
                    onChange={handleInputChange}
                  />
                </div>

              </div>




              <div className="form-group d-flex mt-3">
                <div className="col-md-6">
                  <label htmlFor="exampleInputPassword1">Arrival Date</label>
                  <input
                    type="date"
                    class="form-control"
                    id="exampleInputPassword1"
                    value={arrdate}
                    name="arrdate"
                    onChange={handleInputChange}
                  />
                </div>
                <div class="col-md-6 left">
                  <label htmlFor="exampleInputPassword1">Departure Date</label>
                  <input
                    type="date"
                    class="form-control"
                    id="exampleInputPassword1"
                    value={dipdate}
                    name="dipdate"
                    onChange={handleInputChange}
                  />
                </div>
              </div>


              <div className="form-group row p-4">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookNow;
