import React, { useState, useEffect } from "react";

import HotelBanner from "../component/hotel/HotelBanner";
import FiveStar from "../component/hotel/FiveStar";
import ThreeStar from "../component/hotel/ThreeStar";
import ServiceFormHotel from "../component/hotel/ServiceFormHotel";
import HotleFilter from "../component/hotel/HotleFilter";
import HotelSearch from "../component/hotel/HotelSearch";

import Card from "../component/home/Card";
import PopoularDesignation from "../component/home/PopoularDesignation";
import Testimonial from "../component/home/Testimonial";

export default function HotelBooking() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Hotel Booking";
  }, []);

  let objsearchstart = {
    city_id: '',
    city_name: '',
    price: [],
    star: '',
    facility: ''
    ,
  };
  const [searchobj, setSeacrhObject] = useState(objsearchstart);


  console.log('..message..', searchobj)


  const setsearchkewords = (childData) => {

    setSeacrhObject(childData);
    console.log('..childData after', searchobj)
    // React.setState({ message: childData })
  }

  return (
    <>
      <HotelBanner />
      <ServiceFormHotel parentCallback={setsearchkewords} searchdatarcv={searchobj} />
      {/*
      <Card />

      
      <div class="hotel-page section">
        <div class="container">
          <HotleFilter />


       
 <div className="right-filter-content">
            <FiveStar />
            <ThreeStar />
          </div>
    


        </div>
      </div>
        */}
      <HotelSearch searchdatarcv={searchobj} />

      {/*
      <PopoularDesignation />
      <Testimonial />
      */}
    </>
  );
}
