// constant files

export const ADD_HOTEL_REQUEST = "ADD_HOTEL_REQUEST";
export const ADD_HOTEL_SUCCESS = "ADD_HOTEL_SUCCESS";
export const ADD_HOTEL_FAIL = "ADD_HOTEL_FAIL";

export const UPDATE_HOTEL_REQUEST = "UPDATE_HOTEL_REQUEST";
export const UPDATE_HOTEL_SUCCESS = "UPDATE_HOTEL_SUCCESS";
export const UPDATE_HOTEL_FAIL = "UPDATE_HOTEL_FAIL";

export const DELETE_HOTEL_REQUEST = "DELETE_HOTEL_REQUEST";
export const DELETE_HOTEL_SUCCESS = "DELETE_HOTEL_SUCCESS";
export const DELETE_HOTEL_FAIL = "DELETE_HOTEL_FAIL";

export const GET_ALL_HOTEL_REQUEST = "GET_ALL_HOTEL_REQUEST";
export const GET_ALL_HOTEL_SUCCESS = "GET_ALL_HOTEL_SUCCESS";
export const GET_ALL_HOTEL_FAIL = "GET_ALL_HOTEL_FAIL";

export const GET_SINGLE_HOTEL_REQUEST = "GET_SINGLE_HOTEL_REQUEST";
export const GET_SINGLE_HOTEL_SUCCESS = "GET_SINGLE_HOTEL_SUCCESS";
export const GET_SINGLE_HOTEL_FAIL = "GET_SINGLE_HOTEL_FAIL";

export const HOTEL_CHANGE_STATUS_REQUEST = "HOTEL_CHANGE_STATUS_REQUEST";
export const HOTEL_CHANGE_STATUS_SUCCESS = "HOTEL_CHANGE_STATUS_SUCCESS";
export const HOTEL_CHANGE_STATUS_FAIL = "HOTEL_CHANGE_STATUS_FAIL";


export const GET_HOTEL_CATEGORY_REQUEST = "GET_HOTEL_CATEGORY_REQUEST";
export const GET_HOTEL_CATEGORY_SUCCESS = "GET_HOTEL_CATEGORY_SUCCESS";
export const GET_HOTEL_CATEGORY_FAIL = "GET_HOTEL_CATEGORY_FAIL";


export const GET_HOTEL_FACILITY_REQUEST = "GET_HOTEL_FACILITY_REQUEST";
export const GET_HOTEL_FACILITY_SUCCESS = "GET_HOTEL_FACILITY_SUCCESS";
export const GET_HOTEL_FACILITY_FAIL = "GET_HOTEL_FACILITY_FAIL";