import React, { useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Slider1 from "../../image/s1.png";
import Slider2 from "../../image/s2.png";
import Slider3 from "../../image/s3.png";
import Slider4 from "../../image/s4.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loadAllTourPackages } from "../../redux/action/TourPackagesAction"



const HolidayPackages = () => {
    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]


    };

    const imgpath = ""
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(loadAllTourPackages());


    }, [])

    const Tours = useSelector((state) => state.TourPackages);

    const { result } = Tours;
    console.log(result, "tourrr")

    return (
        <section class="section best-offer">
            <div class="container ">
                <h2 class="title left">Holiday <span>Packages</span></h2>
                <ul class="row gx-2 d-flex justify-content-center holiday_slider_div" >
                    <Slider {...settings}>

                        {result.map((data) => {
                            return (
                                <>

                                    <li>
                                        <div class="inner-content  ">
                                            <Link to={'/tour-product/' + data.id}>

                                                <div class="offer-image">
                                                    <img src={data.image} />
                                                </div>
                                                <div className="offer-content">
                                                    <div className="top"><h2>{data.name}</h2><h4>₹ {data.price} </h4></div>
                                                    <div className="bottom"><p>{data.no_of_days} Days/{data.no_of_nights} Nights </p><p>Starting Price</p></div>
                                                </div>
                                            </Link>

                                        </div>
                                    </li>
                                </>
                            )
                        })}


                        {result.map((data) => {
                            return (
                                <>

                                    <li>
                                        <div class="inner-content  ">
                                            <Link to={'/tour-product/' + data.id}>

                                                <div class="offer-image">
                                                    <img src={data.image} />
                                                </div>
                                                <div className="offer-content">
                                                    <div className="top"><h2>{data.name}</h2><h4>₹ {data.price} </h4></div>
                                                    <div className="bottom"><p>{data.no_of_days} Days/{data.no_of_nights} Nights </p><p>Starting Price</p></div>
                                                </div>
                                            </Link>

                                        </div>
                                    </li>
                                </>
                            )
                        })}
                    </Slider>
                </ul>
            </div>
        </section>
    );
};

export default HolidayPackages;
