import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Slider1 from "../../image/slide1.png";
import Slider2 from "../../image/slide2.png";
import Slider3 from "../../image/slide3.png";
import { Link, useLocation } from "react-router-dom";


export default function Card() {
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };
  return (

    <section class="section best-offer">
      <div class="container best-offer_div">
        <h2 class="title left">Best offers exclusively for you!</h2>

        <Slider {...settings}>
          <div className="card-image" style={{ padding: "0 10px" }}>
            <Link to="/tour-package">

              <img src={Slider1} alt="" />
            </Link>
          </div>
          <div className="card-image" style={{ padding: "0 10px" }} >
            <Link to="/hotel-booking">

              <img src={Slider2} alt="" />
            </Link>
          </div>
          <div className="card-image" style={{ padding: "0 10px" }}>
            <Link to="/taxi-booking">

              <img src={Slider3} alt="" />
            </Link>
          </div>
          <div className="card-image" style={{ padding: "0 10px" }}>
            <Link to="/flight-booking">


              <img src={Slider3} alt="" />
            </Link>
          </div>
        </Slider>
      </div >
    </section >
  );
}
