import React from "react";
import { Card } from "react-bootstrap";
import { PaymentTaxi } from "../../redux/action/TaxiBookingAction";
// import {BookingFormTaxi} from "../../redux/action/TaxiBookingAction"
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import useRazorpay from "react-razorpay";
// import { useParams } from "react-router-dom";
export default function PaymentDetail() {
  const dispatch = useDispatch();

  const PaymentRes = useSelector((state) => state.Taxi.book_res);
  // alert("come")

  // console.log(PaymentRes, "//////////");
  // const InsertId = PaymentRes[0].insertId;

  const TaxiBooking_Details = useSelector((state) => state.Taxi.result);

//   console.log(TaxiBooking_Details, "payment id issss");


const[amount,setAmount]=useState("");

 

  const openPayModal = (options) => {
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const user = sessionStorage.getItem("id");
  const fares = sessionStorage.getItem("fare");
//   const fare = sessionStorage.getItem("fare");
 

const options = {
  key: "rzp_test_HJG5Rtuy8Xh2NB",
  amount: `${fares}`*100, //  = INR 1
  name: "stark",
  description: "some description",
  image: "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png",
  handler: function (response) {
    // alert(response.razorpay_payment_id);
  },
  prefill: {
    name: "starkjatt",
    contact: "787805747",
    email: "stark@demo.com",
  },
  notes: {
    address: "some address",
  },
  theme: {
    color: "#0000FF",
    hide_topbar: false,
  },
};
  return (
    <div className="container">
      <div className="row">
        <div
          className="col-md-12 pt-5"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          <div className="col-md-6 ">
            <Card
              style={{
                width: "40rem",
                backgroundColor: "#3652A2",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Card.Body>
                <Card.Title style={{ color: "white" }}>
                  Payment Details
                </Card.Title>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}
                >
                  <Card style={{ width: "12rem" }}>
                    <Card.Body>
                      <Card.Title>₹ 0 now</Card.Title>
                      <Card.Text>₹34676778 later</Card.Text>
                    </Card.Body>
                  </Card>
                  {/* <Card style={{ width: "6rem" }}>
                    <Card.Body>
                      <Card.Title>Id: {InsertId}</Card.Title>
                    </Card.Body>
                  </Card> */}
                  <Card style={{ width: "12rem" }}>
                    <Card.Body>
                      {TaxiBooking_Details.map((item) => {
                        return (
                          <>
                           {
                            item.id==user? <Card.Title onChange={()=>setAmount(item.totalfare)}>₹ {item.totalfare}</Card.Title>:""
                           }
                          </>
                        );
                      })}

                      <Card.Text>pay now</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                    marginTop: "3%",
                  }}
                >
                  <Card.Text style={{ color: "white" }} className="p-text">
                    ZERO changes to reshedule or cancle
                  </Card.Text>
                  <button
                    className="btn btn-warning btn-booking mb-2 "
                    onClick={() => openPayModal(options)}
                  >
                    Proceed
                  </button>

                  <h1 style={{ color: "white" }}>{PaymentRes.msg}</h1>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-6">
            <Card
              style={{ width: "30rem", marginBottom: "2%", marginLeft: "auto" }}
            >
              <Card.Body>
                <Card.Title>Promise Of Quality</Card.Title>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <img
                    src="https://www.savaari.com/assets/img/bookingpage-images/our-drivers-text.png"
                    style={{ height: "100px", width: "100px" }}
                  ></img>
                  <img
                    src="https://www.savaari.com/assets/img/bookingpage-images/rating-text.png"
                    style={{ height: "100px", width: "100px" }}
                  ></img>
                  <img
                    src="https://www.savaari.com/assets/img/bookingpage-images/vintage.png"
                    style={{ height: "100px", width: "100px" }}
                  ></img>
                </div>
              </Card.Body>
            </Card>

            <Card style={{ width: "30rem", marginLeft: "auto" }}>
              <Card.Body>
                <Card.Title>Your Booking Details</Card.Title>
                <div
                  className="col-md-12"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  <div className="col-md-6">
                    <p className="p-text">Itinerary:</p>
                    <p className="p-text">Pickup Date :</p>
                    <p className="p-text">Car Type : </p>
                    <p className="p-text">Total Fare :</p>
                  </div>
                  <div className="col-md-6">
                    {TaxiBooking_Details.map((item) => {
                      console.log(item, "item");
                      return (
                        <>
                          {item.id == user ? (
                            <>
                              <p className="p-text">
                                Bangalore - New Delhi (Rohini)
                              </p>
                              <p className="p-text">
                                26th March 2022 at 3:30 PM
                              </p>
                              <p className="p-text">{item.name}</p>
                              <p className="p-text">₹ {item.totalfare}</p>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
