import React from "react";
import { Col, Nav, Tab, Row } from "react-bootstrap";

import BookingHistory from "./BookingHistory";
import UpdateProfile from "./UpdateProfile";
import { useDispatch } from "react-redux";
import { signOut } from "../../redux/action/AuthAction";

const Dashboard = () => {
 const dispatch = useDispatch();

const logoutHandler = () => {
  dispatch(signOut());
};

  return (
    <>
      <div className="pt-3">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row className="tab">
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Update Profile</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Booking History</Nav.Link>
                </Nav.Item>

                {/* <Nav.Item>
                  <Nav.Link onClick={logoutHandler} eventKey="forth">
                    LogOut
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <UpdateProfile />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <BookingHistory />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </>
  );
};

export default Dashboard;
