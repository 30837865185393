import React from 'react'
import FooterLogo from '../../image/footer-logo.png'
import phone from "../../image/phone.png";
import email from "../../image/email.png";
import playStore from "../../image/playstore.png";
// import appStore from "../../image/app-store.png";

export default function Footer() {
    return (
        <div className="fotter-main">
            <footer className="section ">
                <div className="container row footer_main_div" >
                    <div className="footer-box " >
                        <div className="footer-logo">
                            <img src={FooterLogo} />
                        </div>
                        <div className="info">
                            <ul>
                                <li>                <img src={phone} />
                                    <a href="#">+91 7827798587</a></li>
                                <li>                <img src={email} />
                                    <a href="#">info@fluttertourism.com</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-box" >
                        <h3 className="footer-title">Information</h3>
                        <ul>
                            <li><a href="/">Home </a></li>
                            <li><a href="#">News </a></li>
                            <li><a href="contact">Contact Us </a></li>
                        </ul>
                    </div>
                    <div className="footer-box" >
                        <h3 className="footer-title">Helpfull Links</h3>
                        <ul>
                            <li><a href="aboutus">About us  </a></li>

                            <li><a href="#">Service  </a></li>
                            <li><a href="#">Privacy   </a></li>
                            <li><a href="terms">Terms &amp; Conditions </a></li>

                        </ul>
                    </div>
                    <div className="footer-box" >
                        <h3 className="footer-title">Available on:</h3>
                        <div className="download-btn">
                            <img src={playStore} />
                            {/* <img src={appStore}/> */}
                        </div>
                        <div className="follow-fotter" >
                            <span className="text">Follow us on:</span>
                            <ul>
                                <li><a href="#!" target="_blank"><i className="fa fa-facebook-f"></i></a></li>
                                <li><a href="#!" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                <li><a href="#!" target="_blank"><i className="fa fa-youtube-play"></i></a></li>
                                <li><a href="#!" target="_blank"><i className="fa fa-twitter"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copyright">
                <div className="container">
                    <p>All right reserved @ codiantbiztech.com</p>
                </div>
            </div>
        </div>
    )
}
