// constant files

export const ADD_BOOKINGROOM_REQUEST = "ADD_BOOKINGROOM_REQUEST";
export const ADD_BOOKINGROOM_SUCCESS = "ADD_BOOKINGROOM_SUCCESS";
export const ADD_BOOKINGROOM_FAIL = "ADD_BOOKINGROOM_FAIL";

export const UPDATE_BOOKINGROOM_REQUEST = "UPDATE_BOOKINGROOM_REQUEST";
export const UPDATE_BOOKINGROOM_SUCCESS = "UPDATE_BOOKINGROOM_SUCCESS";
export const UPDATE_BOOKINGROOM_FAIL = "UPDATE_BOOKINGROOM_FAIL";

export const DELETE_BOOKINGROOM_REQUEST = "DELETE_BOOKINGROOM_REQUEST";
export const DELETE_BOOKINGROOM_SUCCESS = "DELETE_BOOKINGROOM_SUCCESS";
export const DELETE_BOOKINGROOM_FAIL = "DELETE_BOOKINGROOM_FAIL";

export const GET_ALL_BOOKINGROOM_REQUEST = "GET_ALL_BOOKINGROOM_REQUEST";
export const GET_ALL_BOOKINGROOM_SUCCESS = "GET_ALL_BOOKINGROOM_SUCCESS";
export const GET_ALL_BOOKINGROOM_FAIL = "GET_ALL_BOOKINGROOM_FAIL";

export const GET_SINGLE_BOOKINGROOM_REQUEST = "GET_SINGLE_BOOKINGROOM_REQUEST";
export const GET_SINGLE_BOOKINGROOM_SUCCESS = "GET_SINGLE_BOOKINGROOM_SUCCESS";
export const GET_SINGLE_BOOKINGROOM_FAIL = "GET_SINGLE_BOOKINGROOM_FAIL";

export const BOOKINGROOM_CHANGE_STATUS_REQUEST = "BOOKINGROOM_CHANGE_STATUS_REQUEST";
export const BOOKINGROOM_CHANGE_STATUS_SUCCESS = "BOOKINGROOM_CHANGE_STATUS_SUCCESS";
export const BOOKINGROOM_CHANGE_STATUS_FAIL = "BOOKINGROOM_CHANGE_STATUS_FAIL";
