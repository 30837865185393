import React from 'react'
import banner from "../../image/holiday-banner.jpg"

export default function BannerTour() {
  return (
    <section className="inner-banner">
    <img src={banner} alt=""/>
        <div className="container">
        <div className="banner-content">
        <h2 className="">Holiday Packages</h2>

            </div>
        </div>


    </section>
  )
}
