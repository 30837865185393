import Router from "./Router";
import { Provider } from "react-redux";
import store from "./redux/store";
import React from "react";
import "./App.css";
import { useNavigate } from "react-router-dom";
import setAuthorizationToken from "./redux/action/setAuthorization";
import jwtDecode from "jwt-decode";
import { signOut, setCurrentUser } from "./redux/action/AuthAction";

const App = () => {
  // const navigate = useNavigate();

  // console.log(process.env.REACT_APP_API_PATH,"env",process.env.REACT_APP_PROJECT_ENV)

  if (
    localStorage.token !== null &&
    localStorage.token !== undefined &&
    localStorage.token !== ""
  ) {
    setAuthorizationToken(localStorage.token);
    let token = jwtDecode(localStorage.token);

    if (token !== null || token !== undefined || token !== "") {
      store.dispatch(setCurrentUser(token));
    } else {
      store.dispatch(signOut());
      // navigate("/login");
    }
  } else {
    // navigate("/");
  }

  return (
    <div className="App">
      <Provider store={store}>
        <Router />
      </Provider>
    </div>

  );
};

export default App;
