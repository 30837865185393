import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllHotel, loadAllHotelCategory, loadAllHotelFacility } from "../../redux/action/HotelAction";
import { Link, useParams } from "react-router-dom";
import Loader from "../share/Loader";
import Message from "../share/Message";

const HotelSearch = (props) => {


  const pricecheckBoxList = [
    {
      sprice: "0",
      eprice: "999",
      checkstatus: false,
    },
    {
      sprice: "1000",
      eprice: "1999",
      checkstatus: false,
    },
    {
      sprice: "2000",
      eprice: "3999",
      checkstatus: false,
    },
    {
      sprice: "3000",
      eprice: "4999",
      checkstatus: false,
    },
  ];
  const [checkedStatePrice, setCheckedStatePrice] = useState(pricecheckBoxList);


  const starcheckBoxList = [
    {
      id: 1,
      name: "5 Star",
      checkstatus: false,
    },
    {
      id: 2,
      name: "4 Star",
      checkstatus: false,
    },
    {
      id: 3,
      name: "3 Star",
      checkstatus: false,
    },
    {
      id: 4,
      name: "2 Star",
      checkstatus: false,
    },
  ];
  const [checkedStateStar, setCheckedStateStar] = useState([]);

  const facilitycheckBoxList = [
    {
      id: 1,
      name: "wifi",
      checkstatus: false,
    },
    {
      id: 2,
      name: "4 Star",
      checkstatus: false,
    },
    {
      id: 3,
      name: "3 Star",
      checkstatus: false,
    },
    {
      id: 4,
      name: "2 Star",
      checkstatus: false,
    },
  ];
  const [checkedStateFacility, setCheckedStateFacility] = useState([]);



  const dispatch = useDispatch();
  // console.log("dispacth data");
  // console.log(dispatch);

  useEffect(() => {
    console.log('useEffect search..', props.searchdatarcv);

    dispatch(loadAllHotelCategory());
    dispatch(loadAllHotelFacility());

    console.log('useEffect search..', props.searchdatarcv);


    var objrcv = props.searchdatarcv;


    let queryparam = new URLSearchParams();
    if (objrcv.city_id != '') {
      queryparam.append('city_id', objrcv.city_id);
    }
    objrcv.price.map((item, i) => {
      queryparam.append('price', item);

    });
    if (objrcv.star != '') {
      queryparam.append('star', objrcv.star);
    }
    if (objrcv.facility != '') {
      queryparam.append('facility', objrcv.facility);
    }
    console.log('handleSearch form param..', queryparam.toString());
    //dispatch(loadAllHotel(props.searchdatarcv));

    dispatch(loadAllHotel(queryparam.toString()));



  }, []);

  const hotel = useSelector((state) =>
    state.Hotel.result

  );

  const categorydata = useSelector((state) => state.Hotel.categorydata);
  const facilitydata = useSelector((state) => state.Hotel.facilitydata);

  useEffect(() => {
    console.log('useEffect lead c..', categorydata);
    console.log('useEffect lead f..', facilitydata);

    setCheckedStateStar(categorydata);
    setCheckedStateFacility(facilitydata)

  }, [categorydata, facilitydata]);


  //console.log(categorydata);
  //console.log(facilitydata);

  const handleChange = (e) => {
    const checked = e.target.checked;
    const checkedValue = e.target.value;
    checkedStatePrice[checkedValue].checkstatus = checked;

    var objrcv = props.searchdatarcv;
    var sp = [];

    checkedStatePrice.map((item, i) => {
      if (item.checkstatus) {
        sp.push(item.sprice + "-" + item.eprice);
      }
    });

    objrcv.price = sp;



    let queryparam = new URLSearchParams();
    if (objrcv.city_id != '') {
      queryparam.append('city_id', objrcv.city_id);
    }
    objrcv.price.map((item, i) => {
      queryparam.append('price', item);

    });
    if (objrcv.star != '') {
      queryparam.append('star', objrcv.star);
    }
    if (objrcv.facility != '') {
      queryparam.append('facility', objrcv.facility);
    }
    console.log('handleSearch form param..', queryparam.toString());
    //dispatch(loadAllHotel(props.searchdatarcv));
    dispatch(loadAllHotel(queryparam.toString()));

  };

  const handleChangeStar = (e) => {
    const checked = e.target.checked;
    const checkedValue = e.target.value;
    checkedStateStar[checkedValue].checkstatus = checked;

    var objrcv = props.searchdatarcv;
    var sp = [];

    checkedStateStar.map((item, i) => {
      if (item.checkstatus) {
        sp.push(item.id);
      }
    });

    objrcv.star = sp.join(',');




    let queryparam = new URLSearchParams();
    if (objrcv.city_id != '') {
      queryparam.append('city_id', objrcv.city_id);
    }
    objrcv.price.map((item, i) => {
      queryparam.append('price', item);

    });
    if (objrcv.star != '') {
      queryparam.append('star', objrcv.star);
    }
    if (objrcv.facility != '') {
      queryparam.append('facility', objrcv.facility);
    }
    console.log('handleSearch form param..', queryparam.toString());
    //dispatch(loadAllHotel(props.searchdatarcv));
    dispatch(loadAllHotel(queryparam.toString()));

  };

  const handleChangeFacility = (e) => {
    const checked = e.target.checked;
    const checkedValue = e.target.value;
    checkedStateFacility[checkedValue].checkstatus = checked;

    var objrcv = props.searchdatarcv;
    var sp = [];

    checkedStateFacility.map((item, i) => {
      if (item.checkstatus) {
        sp.push(item.id);
      }
    });

    objrcv.facility = sp.join(',');




    let queryparam = new URLSearchParams();
    if (objrcv.city_id != '') {
      queryparam.append('city_id', objrcv.city_id);
    }
    objrcv.price.map((item, i) => {
      queryparam.append('price', item);

    });
    if (objrcv.star != '') {
      queryparam.append('star', objrcv.star);
    }
    if (objrcv.facility != '') {
      queryparam.append('facility', objrcv.facility);
    }
    console.log('handleSearch form param..', queryparam.toString());
    //dispatch(loadAllHotel(props.searchdatarcv));
    dispatch(loadAllHotel(queryparam.toString()));

  };
  const { loading, error } = hotel;
  //  console.log("hotel", hotel);
  // console.log("error")
  // console.log(error);
  let { id } = useParams();
  return (
    <div class="hotel-page section">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message />
      ) : (
        <div class="container">
          <aside className="hotel-filter">

            {/* 
            <div className="filter">
              <h2>Suggested For You in Delhi</h2>
              <ul>
                <li>
                  <span
                    data-testid="checkboxFilter"
                    className=" checkmarkOuter fadeEff"
                  >
                    <input type="checkbox" />
                    <label>Free Cancellation, Zero Payment Now</label>
                  </span>
                </li>
                <li>
                  <span
                    data-testid="checkboxFilter"
                    className=" checkmarkOuter fadeEff"
                  >
                    <input type="checkbox" />
                    <label>Pay at Hotel</label>
                  </span>
                </li>
                <li>
                  <span
                    data-testid="checkboxFilter"
                    className=" checkmarkOuter fadeEff"
                  >
                    <input type="checkbox" />
                    <label>Free consultation</label>
                  </span>
                </li>
                <li>
                  <span
                    data-testid="checkboxFilter"
                    className=" checkmarkOuter fadeEff"
                  >
                    <input type="checkbox" />
                    <label>Free Chat</label>
                  </span>
                </li>
              </ul>
            </div>
            */}
            <div className="filter">
              <h2>Price</h2>
              <ul>

                {checkedStatePrice.map((item, i) => {
                  return (
                    <li>
                      <span
                        data-testid="checkboxFilter"
                        className=" checkmarkOuter fadeEff"
                      >
                        <label>₹ {item.sprice} - ₹ {item.eprice}</label>

                        <input type="checkbox" className="htlchkbox" name="checkboxlist" id="checkboxlist"
                          value={i}
                          onChange={handleChange}
                        />
                      </span>
                    </li>
                  )
                })
                }

              </ul>
            </div>
            <div className="filter">
              <h2>Category</h2>
              <ul>
                {checkedStateStar.map((item, i) => {
                  return (
                    <li>
                      <span
                        data-testid="checkboxFilter"
                        className=" checkmarkOuter fadeEff"
                      >
                        <input type="checkbox" className="htlchkbox" name="checkboxstar" id="checkboxstar"
                          value={i}
                          onChange={handleChangeStar} />
                        <label>{item.name}</label>
                      </span>
                    </li>
                  )
                })
                }

              </ul>
            </div>
            <div className="filter">
              <h2>Facility</h2>
              <ul>
                {checkedStateFacility.map((item, i) => {
                  return (
                    <li>
                      <span
                        data-testid="checkboxFilter"
                        className=" checkmarkOuter fadeEff"
                      >
                        <input type="checkbox" className="htlchkbox" name="checkboxstar" id="checkboxstar"
                          value={i}
                          onChange={handleChangeFacility} />
                        <label>{item.name}</label>
                      </span>
                    </li>
                  )
                })
                }

              </ul>
            </div>
          </aside>

          <div className="right-filter-content">
            <div class="best-offer">

              <ul class="filter-hotel-search">
                {hotel.map((item, i) => {
                  return (
                    <li key={item.id}>
                      <Link to={`/hotel-detail/${item.id}`}>
                        <div class="inner-content">
                          <div class="offer-image">
                            {item.image && item.image == null ? (
                              ""
                            ) : (
                              <img src={item.image} />
                            )}
                          </div>
                          <div class="offer-content">
                            <div class="top">
                              <h2>{item.name}</h2>
                              <h4>Starting Price  ₹ {item.starting_price}</h4>
                            </div>
                            <div class="bottom">
                              <span >Review Score</span>
                              <span className="hotelrating">{item.rating}</span>

                              {item.goi_location &&
                                item.goi_location !== null ? (
                                <p>
                                  <i class="fa fa-map-marker"></i>{" "}
                                  {item.goi_location}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>

            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HotelSearch;
